import Select from "react-select";

const Filter = (props) => {
    return (
        <div className="row mb-4">
            <div className="col-md-4 mobile-mb-s mobile-mr-s">
                <label>Filter Tokens</label>
                <Select
                    placeholder="Select tokens..."
                    options={props.optionSymbols}
                    isMulti
                    value={props.searchTokens}
                    onChange={props.onchangeSearchTokens}
                />
            </div>

            {props.shouldFilterAssetState ? (
                <div className="col-md-2 mobile-mb-s mobile-mr-s">
                    <label>Asset State</label>
                    <Select
                        placeholder="Asset State..."
                        options={[
                            { value: "", label: "All" },
                            { value: "enabled", label: "Enabled" },
                            { value: "disabled", label: "Disabled" },
                        ]}
                        value={props.searchAssetState}
                        onChange={props.onchangeSearchAssetState}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            ) : (
                <div className="col-md-2" />
            )}

            <div className="col-md-2 mobile-mb-s mobile-mr-s">
                <label>Asset Type</label>
                <Select
                    placeholder="Asset Type..."
                    options={[
                        { value: "", label: "All" },
                        { value: "default", label: "Default" },
                        { value: "unlisted", label: "Unlisted" },
                    ]}
                    value={props.searchAssetType}
                    onChange={props.onchangeSearchAssetType}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />
            </div>

            <div className="col-md-4 mobile-mb-s mobile-mr-s">
                <label>Asset Categories</label>
                <Select
                    className=""
                    placeholder="Select Categories..."
                    options={props.categoryOpts}
                    isMulti
                    value={props.searchCategories}
                    onChange={props.onchangeSearchCategories}
                />
            </div>
        </div>
    );
};

export default Filter;
