import BigNumber from "bignumber.js";
import { toT } from "./converter";

function rateTOKEN_ETH(ords, qty, isBid, fee) {
    if (qty == 0 || !ords?.length || qty > ords.reduce((total, ord) => { return total + ord.p * ord.q }, 0))
        return 0
    let total = 0
    let numToken = 0
    let left = qty
    ords.forEach(ord => {
        let tick = ord.p * ord.q
        if (left >= tick) {
            total += tick
            numToken += ord.q
            left -= tick
        } else {
            total += left
            numToken += left / ord.p
            left = 0
        }
    })
    return isBid ? total * (1 - fee) / numToken : total * (1 + fee) / numToken
}

function rateETH_TOKEN(ords, qty, isBid, fee) {
    if (qty == 0 || !ords?.length || qty > ords.reduce((total, ord) => { return total + ord.q }, 0))
        return 0
    let numToken = 0
    let left = qty
    ords.forEach(ord => {
        if (left >= ord.q) {
            numToken += ord.q * ord.p
            left -= ord.q
        } else {
            numToken += left * ord.p
            left = 0
        }
    })
    return isBid ? numToken * (1 - fee) / qty : numToken * (1 + fee) / qty
}

export function mappingAllRate(qtyGetRate, data, kyberTokens = {}, exchanges) {
    // use ETH and USDT as quote
    let usdID = 0
    const quotes = Object.values(kyberTokens).filter(t => t.symbol == "ETH" || t.symbol == "USDT").
        map(t => {
            if (t.symbol == 'USDT') usdID = t.id
            return { id: t.id, symbol: t.symbol }
        })

    const returnObj = {};
    if (!data?.length) return returnObj;

    const ethUSDT = data.find(d => d.base == 1 && d.quote == usdID)?.asks?.[0]?.p || 2000

    quotes.forEach(quote => {
        const qty = quote.symbol == 'ETH' ? qtyGetRate : qtyGetRate * ethUSDT
        // calculate for TOKEN/ETH pair
        data.filter(pair => kyberTokens[pair.base] && pair.quote == quote.id).forEach(pair => {
            const fee = exchanges?.[pair.exchange]?.trading_fee_maker || exchanges?.[pair.exchange]?.trading_fee_taker || 0
            let rateRecord = {
                quoteConversion: quote.symbol,
                exchange: pair.exchange && exchanges[pair.exchange] ? exchanges[pair.exchange].name : pair.exchange,
                exchangeId: pair.exchange,
                pair: pair.base + "-" + pair.quote,
                base: pair.base,
                quote: pair.quote,
                symbol: `${kyberTokens[pair.base].symbol}/${quote.symbol}`,
                afpAsk: rateTOKEN_ETH(pair.asks, qty, false, fee),
                afpBid: rateTOKEN_ETH(pair.bids, qty, true, fee),
            }
            // add _1unit qty for other using
            const _1unitRate = {
                quoteConversion: quote.symbol,
                exchange: "custom",
                pair: pair.base + "--" + pair.quote,
                base: pair.base,
                quote: pair.quote,
                afpAsk: rateTOKEN_ETH(pair.asks, 1, false, fee),
                afpBid: rateTOKEN_ETH(pair.bids, 1, true, fee),
            }
            returnObj[pair.base] = [...(returnObj[pair.base] || []), rateRecord, _1unitRate]
            returnObj[quote.id] = [...(returnObj[quote.id] || []), rateRecord, _1unitRate]
        })

        // calculate for ETH/TOKEN pair
        data.filter(pair => pair.base == quote.id && kyberTokens[pair.quote]).forEach(pair => {
            const fee = exchanges?.[pair.exchange]?.trading_fee_maker || exchanges?.[pair.exchange]?.trading_fee_taker || 0
            let ask = rateETH_TOKEN(pair.asks, qty, false, fee)
            let bid = rateETH_TOKEN(pair.bids, qty, true, fee)
            let rateRecord = {
                quoteConversion: quote.symbol,
                exchange: pair.exchange && exchanges[pair.exchange] ? exchanges[pair.exchange].name : pair.exchange,
                exchangeId: pair.exchange,
                pair: pair.base + "-" + pair.quote,
                base: pair.quote,
                quote: pair.base,
                symbol: `# ${kyberTokens[pair.quote].symbol}/${quote.symbol}`,
                afpAsk: bid > 0 ? 1 / bid : 0,
                afpBid: ask > 0 ? 1 / ask : 0,
            }
            // add _1unit qty for other using
            let _1unitAsk = rateETH_TOKEN(pair.asks, 1, false, fee)
            let _1unitBid = rateETH_TOKEN(pair.bids, 1, true, fee)
            const _1unitRate = {
                quoteConversion: quote.symbol,
                exchange: "custom",
                pair: pair.base + "--" + pair.quote,
                base: pair.quote,
                quote: pair.base,
                afpAsk: _1unitAsk > 0 ? 1 / _1unitAsk : 0,
                afpBid: _1unitBid > 0 ? 1 / _1unitBid : 0,
            }
            returnObj[pair.quote] = [...(returnObj[pair.quote] || []), rateRecord, _1unitRate]
            returnObj[quote.id] = [...(returnObj[quote.id] || []), rateRecord, _1unitRate]
        })

        // calculate for TOKEN/TOKEN pair
        data.filter(pair => pair.base != quote.id && pair.quote != quote.id && kyberTokens[pair.base] && kyberTokens[pair.quote])
            .forEach(pair => {
                let ask = 0
                let bid = 0
                const ethQuoteData = returnObj[pair.quote]?.find(t => (t.pair == `${quote.id}-${pair.quote}` || t.pair == `${pair.quote}-${quote.id}`) && t.exchangeId == pair.exchange && t.quoteConversion == quote.symbol)
                if (ethQuoteData) {
                    const fee = exchanges?.[pair.exchange]?.trading_fee_maker || exchanges?.[pair.exchange]?.trading_fee_taker || 0
                    if (pair.asks && ethQuoteData.afpAsk > 0) {
                        const asks = pair.asks.map(a => {
                            return { ...a, p: a.p * ethQuoteData.afpAsk }
                        })
                        ask = rateTOKEN_ETH(asks, qty, false, fee)
                    }
                    if (pair.bids && ethQuoteData.afpBid > 0) {
                        const bids = pair.bids.map(a => {
                            return { ...a, p: a.p * ethQuoteData.afpBid }
                        })
                        bid = rateTOKEN_ETH(bids, qty, true, fee)
                    }
                }

                let rateRecord = {
                    quoteConversion: quote.symbol,
                    exchange: pair.exchange && exchanges[pair.exchange] ? exchanges[pair.exchange].name : pair.exchange,
                    exchangeId: pair.exchange,
                    pair: pair.base + "-" + pair.quote,
                    base: pair.base,
                    quote: pair.quote,
                    symbol: `* ${kyberTokens[pair.base].symbol}/${kyberTokens[pair.quote].symbol}`,
                    afpAsk: ask,
                    afpBid: bid,
                }

                // add _1unit qty for other using
                let _1unitAsk = 0;
                let _1unitBid = 0;
                const _1unitEthQuoteData = returnObj[pair.quote]?.find(
                    (t) =>
                        (t.pair == `${quote.id}--${pair.quote}` || t.pair == `${pair.quote}--${quote.id}`) &&
                        t.exchange == "custom" &&
                        t.quoteConversion == quote.symbol
                );
                if (_1unitEthQuoteData) {
                    const fee = exchanges?.[pair.exchange]?.trading_fee_maker || exchanges?.[pair.exchange]?.trading_fee_taker || 0;
                    if (pair.asks && _1unitEthQuoteData.afpAsk > 0) {
                        const asks = pair.asks.map((a) => {
                            return { ...a, p: a.p * _1unitEthQuoteData.afpAsk };
                        });
                        _1unitAsk = rateTOKEN_ETH(asks, 1, false, fee);
                    }
                    if (pair.bids && _1unitEthQuoteData.afpBid > 0) {
                        const bids = pair.bids.map((a) => {
                            return { ...a, p: a.p * _1unitEthQuoteData.afpBid };
                        });
                        _1unitBid = rateTOKEN_ETH(bids, 1, true, fee);
                    }
                }

                const _1unitRateRecord = {
                    quoteConversion: quote.symbol,
                    exchange: "custom",
                    pair: pair.base + "--" + pair.quote,
                    base: pair.base,
                    quote: pair.quote,
                    afpAsk: _1unitAsk,
                    afpBid: _1unitBid,
                };

                returnObj[pair.quote] = [...(returnObj[pair.quote] || []), rateRecord, _1unitRateRecord]
                returnObj[pair.base] = [...(returnObj[pair.base] || []), rateRecord, _1unitRateRecord]
            })
    })

    return returnObj;
}

export function mappingAllPendingAmount(data) {
    const returnObj = {};
    Object.keys(data).map((action) => {
        if (action == "deposit" || action == "withdraw") {
            const arrayAction = data[action];

            arrayAction.map((actionData) => {
                const tokenId = actionData.params.asset;
                const amount = actionData.params.amount;

                if (!returnObj[tokenId]) {
                    returnObj[tokenId] = {
                        withdraw: 0,
                        deposit: {},
                    };
                }

                if (action == "withdraw") {
                    if (actionData.mining_status !== "mined") {
                        returnObj[tokenId].withdraw += amount;
                    }
                }

                if (action == "deposit") {
                    if (actionData.exchange_status !== "done" && actionData.mining_status == "mined") {
                        const exchangeId = actionData.params.exchange;
                        if (!returnObj[tokenId].deposit[exchangeId]) {
                            returnObj[tokenId].deposit[exchangeId] = 0;
                        }
                        returnObj[tokenId].deposit[exchangeId] += amount;
                    }
                }
            });
        }
    });

    return returnObj;
}

export function mappingAllExchangeBalance(data, allTokens) {
    const returnObj = {};

    if (!data) return returnObj;
    Object.values(data).map((t) => {
        const exchangeObj = {};
        if (t.exchanges) {
            t.exchanges.map((ex) => {
                exchangeObj[ex.exchange_id] = ex;
            });
            returnObj[t.asset_id] = { ...t, exchanges: exchangeObj };
        }
    });

    return returnObj;
}

export function mappingAllReserveBalance(data) {
    const returnObj = {};
    data.map((t) => {
        returnObj[t.asset_id] = t && t.reserve ? t.reserve.toString() : 0;
    });

    return returnObj;
}

export function filterSetRateAction(arrayAction) {
    const arraySetRate = [];
    const otherActions = [];

    if (arrayAction && Array.isArray(arrayAction) && arrayAction.length) {
        arrayAction.map((item) => {
            if (item.action.toLowerCase() == "set_rates") {
                arraySetRate.push(item);
            } else {
                item.actionDetail =
                    item.action == "trade" || item.action == "perpetual_trade"
                        ? item.params.type + " " + item.params.base + "-" + item.params.quote
                        : item.action + " " + item.params.token;
                otherActions.push(item);
            }
        });
    }

    return {
        arraySetRate,
        otherActions,
    };
}

export function filterOtherActivities(arrayAction, tokens) {
    const otherActions = [];
    const filterOptions = {
        action: [],
        destination: [],
        exchange: [],
        mining: [],
    };
    if (arrayAction && Array.isArray(arrayAction) && arrayAction.length) {
        arrayAction.map((item) => {
            if (item.action.toLowerCase() !== "set_rates") {
                item.actionDetail = item.action == "perpetual_trade" ? "(perpetual) " : "";
                if (item.action == "trade" || item.action == "perpetual_trade") {
                    item.actionDetail += item.params.type + " " + item.params.base + "-" + item.params.quote;

                    let displayAmount = "";
                    if (item.params && item.params.amount) {
                        if (item.result && item.result.remaining) {
                            const remaining = item.result.remaining;
                            const total = item.params.amount;
                            const filled = total - remaining;
                            const filledPercent = ((filled / total) * 100).toFixed(2);
                            displayAmount = `${filled.toFixed(4)}/${total.toFixed(4)} (${filledPercent}%)`;
                        } else {
                            displayAmount = `${item.params.amount.toFixed(4)}/${item.params.amount.toFixed(4)} (100%)`;
                        }
                    }

                    item.display_amount = displayAmount;
                    if (item.params) {
                        item.params.time_in_force = item.params?.time_in_force?.toUpperCase() || "GTC"
                    }

                } else {
                    item.display_amount = item.params.amount;
                    if (tokens && tokens[item.params.asset]) {
                        item.actionDetail = item.action.replace("cross_margin_transfer", "transfer").replaceAll("_", " ") + " " + tokens[item.params.asset].name;
                    } else {
                        item.actionDetail = item.action.replace("cross_margin_transfer", "transfer").replaceAll("_", " ") + " undefined";
                    }
                }

                if (filterOptions.action.map((i) => i.value).indexOf(item.actionDetail) < 0) {
                    filterOptions.action.push({ value: item.actionDetail, label: item.actionDetail });
                }

                if (filterOptions.destination.map((i) => i.value).indexOf(item.destination) < 0) {
                    filterOptions.destination.push({ value: item.destination });
                }

                if (filterOptions.exchange.map((i) => i.value).indexOf(item.exchange_status) < 0) {
                    filterOptions.exchange.push({ value: item.exchange_status });
                }

                if (filterOptions.mining.map((i) => i.value).indexOf(item.mining_status) < 0) {
                    filterOptions.mining.push({ value: item.mining_status });
                }
            } else {
                item.actionDetail = "Set Rate";
            }
            otherActions.push(item);
        });
    }

    otherActions.sort((a, b) => +b.timestamp - +a.timestamp);

    return {
        otherActions,
        filterOptions,
    };
}

export function mappingOpenOder(orderData, exchanges) {
    const returnArrayOrders = [];
    const filterParams = {
        exchange: [],
        pair: [],
        type: [],
        side: [],
    };

    if (orderData) {
        Object.keys(orderData).map((exchangeId) => {
            const arrayExchangeOrders = orderData[exchangeId];
            const exchangeData = exchanges[exchangeId];

            if (arrayExchangeOrders && arrayExchangeOrders.length) {
                arrayExchangeOrders.map((order) => {
                    const exchange = exchangeData ? exchangeData.name : exchangeId;
                    const pair = order.Base + " / " + order.Quote;
                    returnArrayOrders.push({
                        ...order,
                        pair,
                        exchange,
                    });

                    if (filterParams.exchange.map((i) => i.value).indexOf(exchange) < 0)
                        filterParams.exchange.push({ value: exchange });
                    if (filterParams.pair.map((i) => i.value).indexOf(pair) < 0)
                        filterParams.pair.push({ value: pair, label: pair });
                    if (filterParams.type.map((i) => i.value).indexOf(order.Type) < 0)
                        filterParams.type.push({ value: order.Type });
                    if (filterParams.side.map((i) => i.value).indexOf(order.Side) < 0)
                        filterParams.side.push({ value: order.Side });
                });
            }
        });
    }

    return { arrayOrders: returnArrayOrders, filterParams };
}

export function mappingSetRateHistory(arraySetRate) {
    const ratesObj = {};
    if (arraySetRate && Array.isArray(arraySetRate) && arraySetRate.length) {
        // let arrayZeroRate = Array(arraySetRate[0].Params.buys.length).fill(0)

        arraySetRate.map((item) => {
            // if (CONSTANTS.HIDE_ZERO_SET_RATE && JSON.stringify(arrayZeroRate) == JSON.stringify(item.Params.buys) && JSON.stringify(arrayZeroRate) == JSON.stringify(item.Params.sells)) return
            item.params.assets.map((assetId, index) => {
                if (!ratesObj[assetId]) ratesObj[assetId] = [];
                ratesObj[assetId].unshift({
                    date: new Date(Math.round(item.id.split("|")[0] / 1000000)),
                    // date: new Date(Math.round(item.id.split('|')[0] / 1000000)).toLocaleString(),
                    buy: toT(item.params.buys[index], 18, 8, true),
                    sell: toT(item.params.sells[index], 18, 8),
                    msgs: item.params.msgs[index],
                    triggers: item.params.triggers[index],
                    afpMid: toT(item.params.afpMid[index], 18, 8),
                    rawData: item,
                    blockNumber: item.result.blockNumber,
                    error: item.result.error,
                });
                // ratesObj[assetId].buys.push(item.Params.buys[index])
                // ratesObj[assetId].sells.push(item.Params.sells[index])
            });
        });
    }

    return ratesObj;
}

export function mappingCoreRates(arrayCoreRate) {
    const ratesObj = {};
    if (arrayCoreRate && Array.isArray(arrayCoreRate) && arrayCoreRate.length) {
        arrayCoreRate.map((item) => {
            const returnDate = new Date(+item.return_time);
            // let timeStamp = new Date(item.Timestamp)
            Object.keys(item.data).map((tokenSymbol) => {
                if (!ratesObj[tokenSymbol]) ratesObj[tokenSymbol] = [];

                const bigBaseBuy = new BigNumber(item.data[tokenSymbol].base_buy.toString());
                const bigCompactBuy = new BigNumber(item.data[tokenSymbol].compact_buy.toString());

                const bigBaseSell = new BigNumber(item.data[tokenSymbol].base_sell.toString());
                const bigCompactSell = new BigNumber(item.data[tokenSymbol].compact_sell.toString());

                const bigBuy = bigBaseBuy.isZero()
                    ? new BigNumber(0)
                    : bigCompactBuy.dividedBy(1000).plus(1).multipliedBy(bigBaseBuy).pow(-1);
                const bigSell = bigCompactSell.dividedBy(1000).plus(1).multipliedBy(bigBaseSell);

                ratesObj[tokenSymbol].unshift({
                    date: returnDate,
                    buy: bigBuy.decimalPlaces(8).toString(),
                    sell: bigSell.decimalPlaces(8).toString(),
                    // blockNumber: 827348923
                    blockNumber: item.block_number + " - " + item.to_block_number,
                    rawData: item.data[tokenSymbol],
                });
            });
        });
    }
    return ratesObj;
}

export function mappingReserveRate(newData, reserveAddr) {
    const returnData = {};
    Object.keys(newData).map((reserveAddr, i) => {
        const isThirdParty = false;
        Object.keys(newData[reserveAddr]).map((rateSymbol) => {
            const tokenSymbol = rateSymbol.split("-")[1];
            const arrayData = newData[reserveAddr][rateSymbol];
            arrayData.map((item) => {
                let bigBuyReserveRate = new BigNumber(item.rates.buy_reserve_rate.toString());
                let bigBuySanityRate = new BigNumber(item.rates.buy_sanity_rate.toString());
                const bigSellReserveRate = new BigNumber(item.rates.sell_reserve_rate.toString());
                const bigSellSanityRate = new BigNumber(item.rates.sell_sanity_rate.toString());

                bigBuyReserveRate = bigBuyReserveRate.isZero() ? new BigNumber(0) : bigBuyReserveRate.pow(-1);
                bigBuySanityRate = bigBuySanityRate.isZero() ? new BigNumber(0) : bigBuySanityRate.pow(-1);

                if (!returnData[tokenSymbol]) returnData[tokenSymbol] = {};
                const blockNumber = item.from_block;
                if (!returnData[tokenSymbol][blockNumber]) returnData[tokenSymbol][blockNumber] = {};

                if (isThirdParty) {
                    returnData[tokenSymbol][blockNumber] = {
                        ...returnData[tokenSymbol][blockNumber],

                        buyReserve3th: bigBuyReserveRate.decimalPlaces(8).toString(),
                        buySanity3th: bigBuySanityRate.decimalPlaces(8).toString(),
                        sellReserve3th: bigSellReserveRate.decimalPlaces(8).toString(),
                        sellSanity3th: bigSellSanityRate.decimalPlaces(8).toString(),
                        Timestamp3th: item.timestamp,
                        toBlockNumber3th: item.to_block,
                    };
                } else {
                    returnData[tokenSymbol][blockNumber] = {
                        ...returnData[tokenSymbol][blockNumber],

                        buyReserve: bigBuyReserveRate.decimalPlaces(8).toString(),
                        buySanity: bigBuySanityRate.decimalPlaces(8).toString(),
                        sellReserve: bigSellReserveRate.decimalPlaces(8).toString(),
                        sellSanity: bigSellSanityRate.decimalPlaces(8).toString(),
                        Timestamp: item.timestamp,
                        toBlockNumber: item.to_block,
                    };
                }
            });
        });
    });

    return returnData;
}

export function mappingPWIS(data) {
    // {
    //   KNC: {ask: {a,b,c...}, bid: {}}
    // }
    const returnObj = {};
    data.map((assetItem) => {
        const assetId = assetItem.id;
        if (!assetItem.pwi) return;

        returnObj[assetId] = {
            // token: assetId,
            a_ask: assetItem.pwi.ask.a,
            a_bid: assetItem.pwi.bid.a,

            b_ask: assetItem.pwi.ask.b,
            b_bid: assetItem.pwi.bid.b,

            c_ask: assetItem.pwi.ask.c,
            c_bid: assetItem.pwi.bid.c,

            min_min_spread_ask: assetItem.pwi.ask.min_min_spread,
            min_min_spread_bid: assetItem.pwi.bid.min_min_spread,

            price_multiply_factor_ask: assetItem.pwi.ask.price_multiply_factor,
            price_multiply_factor_bid: assetItem.pwi.bid.price_multiply_factor,
        };
    });

    return returnObj;
}

