import React, { Component } from "react";
import { connect } from "react-redux";
import TableDataView from "./tableData.view";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./table.scss";
import Pagination from "./pagination";
import { saveTableConfig } from "../../../actions/globalActions";

@connect((store) => {
    const allTokens = store.global.tokens;
    const tables = store.global.tables;

    return {
        tables,
        allTokens,
    };
})
class TableData extends Component {
    constructor(props) {
        super(props);
        // this.intervalID = true
        const savedPageSize = localStorage.getItem((props.title || props.id) + "_page_size");
        this.state = {
            isOpenModal: false,
            isPivot: false,
            modalContent: null,
            pageSize: savedPageSize ? JSON.parse(savedPageSize) : props.initialPageSize || 20,
            page: 0,
            showColumn:
                props.id && props.tables && props.tables[props.id] ? props.tables[props.id] : props.defaultColumn,
        };
    }

    toggleModal(row) {
        if (this.state.isOpenModal) {
            this.setState({ isOpenModal: !this.state.isOpenModal });
            return;
        }

        this.setState({
            modalContent: row,
            isOpenModal: !this.state.isOpenModal,
        });
    }

    toggleGroup() {
        this.setState({
            isPivot: !this.state.isPivot,
        });
    }
    changePageSize(e) {
        localStorage.setItem((this.props.title || this.props.id) + "_page_size", e.target.value);
        this.setState({
            pageSize: e.target.value,
            page: 0,
        });
    }
    changePivot(checked) {
        this.setState({
            isPivot: checked,
        });
    }
    getTrProps(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    this.props.onClickRow && this.props.onClickRow(state, rowInfo);
                },
                className: this.props.rowClassName && this.props.rowClassName(state, rowInfo),
            };
        }
        return {};
    }

    getTdProps(state, rowInfo, column, instance) {
        return {
            className: this.props.cellClassName && this.props.cellClassName(state, rowInfo, column, instance),
        };
    }

    changeShowColumn(columnId) {
        const showColumnState = this.state.showColumn || {};
        showColumnState[columnId] = !showColumnState[columnId];
        this.setState({ showColumn: showColumnState });
        if (this.props.id) {
            this.props.dispatch(saveTableConfig(this.props.id, showColumnState));
        }
    }

    shouldShowColumn(column) {
        if (!this.state.showColumn) return true;

        if (!this.state.showColumn[column.id]) return false;

        return true;
    }

    render() {
        const tableData = (
            <ReactTable
                showPaginationTop={this.props.isShowPaginateTop}
                showPagination={this.props.isShowPaginate || false}
                className="-striped -highlight"
                data={this.props.data}
                minRows={1}
                pageSize={
                    this.props.isShowPageSize && this.props.isShowPaginate
                        ? this.state.pageSize
                        : this.props.data && this.props.data.length
                        ? this.props.data.length
                        : 10
                }
                columns={this.props.columns.map((c) => ({ ...c, show: this.shouldShowColumn(c) }))}
                pivotBy={this.state.isPivot ? this.props.groupBy || [] : []}
                getTrProps={this.getTrProps.bind(this)}
                getTdProps={this.getTdProps.bind(this)}
                previousText={<i className="fa fa-arrow-left" />}
                nextText={<i className="fa fa-arrow-right" />}
                page={this.state.page}
                onPageChange={(page) => this.setState({ page })}
                style={this.props.style}
                {...(this.props.isShowPaginate ? { PaginationComponent: Pagination, FilterComponent: Pagination } : {})}
                {...this.props.overWriteProps}
            />
        );

        return (
            <TableDataView
                hasNullToken={this.props.hasNullToken}
                allTokens={this.props.allTokens}
                title={this.props.title}
                width={this.props.width}
                isShowGroup={this.props.groupBy && this.props.groupBy.length ? true : false}
                isPivot={this.state.isPivot}
                isShowPageSize={this.props.isShowPageSize}
                pageSize={this.state.pageSize}
                isOpenModal={this.state.isOpenModal}
                modalContent={this.state.modalContent}
                toggleModal={this.toggleModal.bind(this)}
                tableData={tableData}
                confirmation={this.props.confirmation}
                changePageSize={this.changePageSize.bind(this)}
                changePivot={this.changePivot.bind(this)}
                buttonElements={this.props.buttonElements}
                isShowSelectColumn={this.props.defaultColumn ? true : false}
                arrayColumns={this.props.columns}
                showColumn={this.state.showColumn}
                changeShowColumn={this.changeShowColumn.bind(this)}
                searchTokens={this.props.searchTokens}
                searchChange={this.props.searchChange}
                componentLeftToPageSize={this.props.componentLeftToPageSize}
                rfqPairs={this.props.rfqPairs}
                rfqPairsFilter={this.props.rfqPairsFilter}
            />
        );
    }
}

export default TableData;
