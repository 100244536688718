const ABIS = {
    ERC20: [
        {
            constant: true,
            inputs: [],
            name: "name",
            outputs: [{ name: "", type: "string" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "minter",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "_spender", type: "address" },
                { name: "_value", type: "uint256" },
            ],
            name: "approve",
            outputs: [{ name: "o_success", type: "bool" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "totalSupply",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "_recipient", type: "address" },
                { name: "_value", type: "uint256" },
            ],
            name: "createIlliquidToken",
            outputs: [{ name: "o_success", type: "bool" }],
            payable: false,
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "_from", type: "address" },
                { name: "_recipient", type: "address" },
                { name: "_amount", type: "uint256" },
            ],
            name: "transferFrom",
            outputs: [{ name: "o_success", type: "bool" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "endMintingTime",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "decimals",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "_recipient", type: "address" },
                { name: "_value", type: "uint256" },
            ],
            name: "createToken",
            outputs: [{ name: "o_success", type: "bool" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "_owner", type: "address" }],
            name: "balanceOf",
            outputs: [{ name: "balance", type: "uint256" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "", type: "address" }],
            name: "illiquidBalance",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "symbol",
            outputs: [{ name: "", type: "string" }],
            payable: false,
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "_recipient", type: "address" },
                { name: "_amount", type: "uint256" },
            ],
            name: "transfer",
            outputs: [{ name: "o_success", type: "bool" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "LOCKOUT_PERIOD",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "_owner", type: "address" },
                { name: "_spender", type: "address" },
            ],
            name: "allowance",
            outputs: [{ name: "o_remaining", type: "uint256" }],
            payable: false,
            type: "function",
        },
        { constant: false, inputs: [], name: "makeLiquid", outputs: [], payable: false, type: "function" },
        {
            inputs: [
                { name: "_minter", type: "address" },
                { name: "_endMintingTime", type: "uint256" },
            ],
            payable: false,
            type: "constructor",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "_from", type: "address" },
                { indexed: true, name: "_recipient", type: "address" },
                { indexed: false, name: "_value", type: "uint256" },
            ],
            name: "Transfer",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "_owner", type: "address" },
                { indexed: true, name: "_spender", type: "address" },
                { indexed: false, name: "_value", type: "uint256" },
            ],
            name: "Approval",
            type: "event",
        },
    ],
    KYBER_NETWORK: [
        {
            constant: false,
            inputs: [{ name: "alerter", type: "address" }],
            name: "removeAlerter",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "reserve", type: "address" },
                { name: "source", type: "address" },
                { name: "dest", type: "address" },
                { name: "add", type: "bool" },
            ],
            name: "listPairForReserve",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "kyberWhiteList",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "getReserves",
            outputs: [{ name: "", type: "address[]" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "pendingAdmin",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "getOperators",
            outputs: [{ name: "", type: "address[]" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "negligiblePriceDiff",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "token", type: "address" },
                { name: "amount", type: "uint256" },
                { name: "sendTo", type: "address" },
            ],
            name: "withdrawToken",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "maxGasPrice",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newAlerter", type: "address" }],
            name: "addAlerter",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "feeBurnerContract",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "expectedRateContract",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "user", type: "address" }],
            name: "getUserCapInWei",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newAdmin", type: "address" }],
            name: "transferAdmin",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "_enable", type: "bool" }],
            name: "setEnable",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [],
            name: "claimAdmin",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "", type: "address" }],
            name: "isReserve",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "getAlerters",
            outputs: [{ name: "", type: "address[]" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "source", type: "address" },
                { name: "dest", type: "address" },
                { name: "srcQuantity", type: "uint256" },
            ],
            name: "getExpectedRate",
            outputs: [
                { name: "expectedPrice", type: "uint256" },
                { name: "slippagePrice", type: "uint256" },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "", type: "uint256" }],
            name: "reserves",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newOperator", type: "address" }],
            name: "addOperator",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "reserve", type: "address" },
                { name: "add", type: "bool" },
            ],
            name: "addReserve",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "enable",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "operator", type: "address" }],
            name: "removeOperator",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "source", type: "address" },
                { name: "srcAmount", type: "uint256" },
                { name: "dest", type: "address" },
                { name: "destAddress", type: "address" },
                { name: "maxDestAmount", type: "uint256" },
                { name: "minConversionRate", type: "uint256" },
                { name: "walletId", type: "address" },
            ],
            name: "walletTrade",
            outputs: [{ name: "", type: "uint256" }],
            payable: true,
            stateMutability: "payable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "_whiteList", type: "address" },
                { name: "_expectedRate", type: "address" },
                { name: "_feeBurner", type: "address" },
                { name: "_maxGasPrice", type: "uint256" },
                { name: "_negligibleDiff", type: "uint256" },
            ],
            name: "setParams",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "source", type: "address" },
                { name: "dest", type: "address" },
                { name: "srcQty", type: "uint256" },
            ],
            name: "findBestRate",
            outputs: [
                { name: "", type: "uint256" },
                { name: "", type: "uint256" },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "source", type: "address" },
                { name: "srcAmount", type: "uint256" },
                { name: "dest", type: "address" },
                { name: "destAddress", type: "address" },
                { name: "maxDestAmount", type: "uint256" },
                { name: "minConversionRate", type: "uint256" },
                { name: "walletId", type: "address" },
            ],
            name: "trade",
            outputs: [{ name: "", type: "uint256" }],
            payable: true,
            stateMutability: "payable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "amount", type: "uint256" },
                { name: "sendTo", type: "address" },
            ],
            name: "withdrawEther",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "getNumReserves",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "token", type: "address" },
                { name: "user", type: "address" },
            ],
            name: "getBalance",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "admin",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ name: "_admin", type: "address" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "constructor",
        },
        { payable: true, stateMutability: "payable", type: "fallback" },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "sender", type: "address" },
                { indexed: false, name: "source", type: "address" },
                { indexed: false, name: "dest", type: "address" },
                { indexed: false, name: "actualSrcAmount", type: "uint256" },
                { indexed: false, name: "actualDestAmount", type: "uint256" },
            ],
            name: "Trade",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "reserve", type: "address" },
                { indexed: false, name: "add", type: "bool" },
            ],
            name: "AddReserve",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "reserve", type: "address" },
                { indexed: false, name: "source", type: "address" },
                { indexed: false, name: "dest", type: "address" },
                { indexed: false, name: "add", type: "bool" },
            ],
            name: "ListPairsForReserve",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "sender", type: "address" },
                { indexed: false, name: "amount", type: "uint256" },
            ],
            name: "EtherRecival",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "token", type: "address" },
                { indexed: false, name: "amount", type: "uint256" },
                { indexed: false, name: "sendTo", type: "address" },
            ],
            name: "WithdrawToken",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "amount", type: "uint256" },
                { indexed: false, name: "sendTo", type: "address" },
            ],
            name: "WithdrawEther",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: "pendingAdmin", type: "address" }],
            name: "TransferAdmin",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newAdmin", type: "address" },
                { indexed: false, name: "previousAdmin", type: "address" },
            ],
            name: "ClaimAdmin",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newAlerter", type: "address" },
                { indexed: false, name: "isAdd", type: "bool" },
            ],
            name: "AddAlerter",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newOperator", type: "address" },
                { indexed: false, name: "isAdd", type: "bool" },
            ],
            name: "AddOperator",
            type: "event",
        },
    ],
    KYBER_WRAPPER: [
        {
            constant: true,
            inputs: [
                { name: "x", type: "bytes14" },
                { name: "byteInd", type: "uint256" },
            ],
            name: "getInt8FromByte",
            outputs: [{ name: "", type: "int8" }],
            payable: false,
            stateMutability: "pure",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "reserve", type: "address" },
                { name: "tokens", type: "address[]" },
            ],
            name: "getBalances",
            outputs: [{ name: "", type: "uint256[]" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "ratesContract", type: "address" },
                { name: "tokenList", type: "address[]" },
            ],
            name: "getTokenIndicies",
            outputs: [
                { name: "", type: "uint256[]" },
                { name: "", type: "uint256[]" },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "reserve", type: "address" },
                { name: "srcs", type: "address[]" },
                { name: "dests", type: "address[]" },
            ],
            name: "getReserveRate",
            outputs: [
                { name: "", type: "uint256[]" },
                { name: "", type: "uint256[]" },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "x", type: "bytes14" },
                { name: "byteInd", type: "uint256" },
            ],
            name: "getByteFromBytes14",
            outputs: [{ name: "", type: "bytes1" }],
            payable: false,
            stateMutability: "pure",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "network", type: "address" },
                { name: "srcs", type: "address[]" },
                { name: "dests", type: "address[]" },
                { name: "qty", type: "uint256[]" },
            ],
            name: "getExpectedRates",
            outputs: [
                { name: "", type: "uint256[]" },
                { name: "", type: "uint256[]" },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "ratesContract", type: "address" },
                { name: "tokenList", type: "address[]" },
            ],
            name: "getTokenRates",
            outputs: [
                { name: "", type: "uint256[]" },
                { name: "", type: "uint256[]" },
                { name: "", type: "int8[]" },
                { name: "", type: "int8[]" },
                { name: "", type: "uint256[]" },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
    ],
    RATE_QUERY_HELPER: [
        {
            inputs: [{ internalType: "address", name: "_admin", type: "address" }],
            stateMutability: "nonpayable",
            type: "constructor",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: "address", name: "newAdmin", type: "address" },
                { indexed: false, internalType: "address", name: "previousAdmin", type: "address" },
            ],
            name: "AdminClaimed",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: "address", name: "newAlerter", type: "address" },
                { indexed: false, internalType: "bool", name: "isAdd", type: "bool" },
            ],
            name: "AlerterAdded",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
                { indexed: false, internalType: "address", name: "sendTo", type: "address" },
            ],
            name: "EtherWithdraw",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: "address", name: "newOperator", type: "address" },
                { indexed: false, internalType: "bool", name: "isAdd", type: "bool" },
            ],
            name: "OperatorAdded",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, internalType: "contract IERC20", name: "token", type: "address" },
                { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
                { indexed: false, internalType: "address", name: "sendTo", type: "address" },
            ],
            name: "TokenWithdraw",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, internalType: "address", name: "pendingAdmin", type: "address" }],
            name: "TransferAdminPending",
            type: "event",
        },
        {
            inputs: [{ internalType: "address", name: "newAlerter", type: "address" }],
            name: "addAlerter",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "newOperator", type: "address" }],
            name: "addOperator",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [],
            name: "admin",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        { inputs: [], name: "claimAdmin", outputs: [], stateMutability: "nonpayable", type: "function" },
        {
            inputs: [],
            name: "getAlerters",
            outputs: [{ internalType: "address[]", name: "", type: "address[]" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "getOperators",
            outputs: [{ internalType: "address[]", name: "", type: "address[]" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "reserve", type: "address" },
                { internalType: "contract IERC20", name: "token", type: "address" },
                { internalType: "uint256", name: "weiAmount", type: "uint256" },
            ],
            name: "getRateWithEth",
            outputs: [
                { internalType: "uint256", name: "sellRate", type: "uint256" },
                { internalType: "uint256", name: "buyRate", type: "uint256" },
                { internalType: "uint256", name: "tweiAmount", type: "uint256" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "reserve", type: "address" },
                { internalType: "contract IERC20", name: "token", type: "address" },
                { internalType: "uint256", name: "tweiAmount", type: "uint256" },
            ],
            name: "getRateWithToken",
            outputs: [
                { internalType: "uint256", name: "buyRate", type: "uint256" },
                { internalType: "uint256", name: "sellRate", type: "uint256" },
                { internalType: "uint256", name: "weiAmount", type: "uint256" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "reserve", type: "address" },
                { internalType: "contract IERC20[]", name: "tokens", type: "address[]" },
                { internalType: "uint256", name: "weiAmount", type: "uint256" },
            ],
            name: "getRatesWithEth",
            outputs: [
                { internalType: "uint256[]", name: "sellRates", type: "uint256[]" },
                { internalType: "uint256[]", name: "buyRates", type: "uint256[]" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { internalType: "address", name: "reserve", type: "address" },
                { internalType: "contract IERC20[]", name: "tokens", type: "address[]" },
                { internalType: "uint256", name: "tweiAmount", type: "uint256" },
            ],
            name: "getRatesWithToken",
            outputs: [
                { internalType: "uint256[]", name: "sellRates", type: "uint256[]" },
                { internalType: "uint256[]", name: "buyRates", type: "uint256[]" },
            ],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [],
            name: "pendingAdmin",
            outputs: [{ internalType: "address", name: "", type: "address" }],
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "alerter", type: "address" }],
            name: "removeAlerter",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "operator", type: "address" }],
            name: "removeOperator",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
            name: "transferAdmin",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
            name: "transferAdminQuickly",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "uint256", name: "amount", type: "uint256" },
                { internalType: "address payable", name: "sendTo", type: "address" },
            ],
            name: "withdrawEther",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            inputs: [
                { internalType: "contract IERC20", name: "token", type: "address" },
                { internalType: "uint256", name: "amount", type: "uint256" },
                { internalType: "address", name: "sendTo", type: "address" },
            ],
            name: "withdrawToken",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
        },
    ],
    KYBER_WALLET: [
        {
            constant: true,
            inputs: [],
            name: "ETH_TOKEN_ADDRESS",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "srcToken", type: "address" },
                { name: "srcAmount", type: "uint256" },
                { name: "destToken", type: "address" },
                { name: "maxDestAmount", type: "uint256" },
                { name: "minRate", type: "uint256" },
                { name: "destination", type: "address" },
                { name: "destinationData", type: "bytes" },
                { name: "onlyApproveTokens", type: "bool" },
                { name: "throwOnFail", type: "bool" },
            ],
            name: "convertAndCall",
            outputs: [],
            payable: false,
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "network", type: "address" }],
            name: "setKyberNetwork",
            outputs: [],
            payable: false,
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "token", type: "address" },
                { name: "from", type: "address" },
                { name: "amount", type: "uint256" },
            ],
            name: "recieveTokens",
            outputs: [],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "owner",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            type: "function",
        },
        { constant: false, inputs: [], name: "recieveEther", outputs: [], payable: true, type: "function" },
        {
            constant: false,
            inputs: [
                { name: "to", type: "address" },
                { name: "value", type: "uint256" },
                { name: "data", type: "bytes" },
            ],
            name: "execute",
            outputs: [],
            payable: false,
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "kyberNetwork",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            type: "function",
        },
        { inputs: [{ name: "_kyberNetwork", type: "address" }], payable: false, type: "constructor" },
        { payable: true, type: "fallback" },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "origin", type: "address" },
                { indexed: false, name: "error", type: "uint256" },
                { indexed: false, name: "errorInfo", type: "uint256" },
            ],
            name: "ErrorReport",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "owner", type: "address" },
                { indexed: false, name: "kyberNetwork", type: "address" },
            ],
            name: "NewWallet",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "sender", type: "address" },
                { indexed: false, name: "network", type: "address" },
            ],
            name: "SetKyberNetwork",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "sender", type: "address" },
                { indexed: false, name: "amountInWei", type: "uint256" },
            ],
            name: "IncomingEther",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "from", type: "address" },
                { indexed: false, name: "token", type: "address" },
                { indexed: false, name: "amount", type: "uint256" },
            ],
            name: "IncomingTokens",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "sender", type: "address" },
                { indexed: false, name: "destination", type: "address" },
                { indexed: false, name: "destAmount", type: "uint256" },
            ],
            name: "ConvertAndCall",
            type: "event",
        },
    ],

    KYBER_RESERVE: [
        {
            constant: false,
            inputs: [],
            name: "enableTrade",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "alerter", type: "address" }],
            name: "removeAlerter",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "pendingAdmin",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "getOperators",
            outputs: [{ name: "", type: "address[]" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "token", type: "address" },
                { name: "amount", type: "uint256" },
                { name: "sendTo", type: "address" },
            ],
            name: "withdrawToken",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newAlerter", type: "address" }],
            name: "addAlerter",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "sanityRatesContract",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "token", type: "address" },
                { name: "addr", type: "address" },
                { name: "approve", type: "bool" },
            ],
            name: "approveWithdrawAddress",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "token", type: "address" },
                { name: "amount", type: "uint256" },
                { name: "destination", type: "address" },
            ],
            name: "withdraw",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [],
            name: "disableTrade",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "srcToken", type: "address" },
                { name: "srcAmount", type: "uint256" },
                { name: "destToken", type: "address" },
                { name: "destAddress", type: "address" },
                { name: "conversionRate", type: "uint256" },
                { name: "validate", type: "bool" },
            ],
            name: "trade",
            outputs: [{ name: "", type: "bool" }],
            payable: true,
            stateMutability: "payable",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newAdmin", type: "address" }],
            name: "transferAdmin",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [],
            name: "claimAdmin",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newAdmin", type: "address" }],
            name: "transferAdminQuickly",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "getAlerters",
            outputs: [{ name: "", type: "address[]" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "src", type: "address" },
                { name: "dest", type: "address" },
                { name: "srcQty", type: "uint256" },
                { name: "blockNumber", type: "uint256" },
            ],
            name: "getConversionRate",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newOperator", type: "address" }],
            name: "addOperator",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "src", type: "address" },
                { name: "dest", type: "address" },
                { name: "dstQty", type: "uint256" },
                { name: "rate", type: "uint256" },
            ],
            name: "getSrcQty",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "operator", type: "address" }],
            name: "removeOperator",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "_kyberNetwork", type: "address" },
                { name: "_conversionRates", type: "address" },
                { name: "_sanityRates", type: "address" },
            ],
            name: "setContracts",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "kyberNetwork",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "amount", type: "uint256" },
                { name: "sendTo", type: "address" },
            ],
            name: "withdrawEther",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "conversionRatesContract",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "tradeEnabled",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "", type: "bytes32" }],
            name: "approvedWithdrawAddresses",
            outputs: [{ name: "", type: "bool" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "admin",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "token", type: "address" }],
            name: "getBalance",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "src", type: "address" },
                { name: "dest", type: "address" },
                { name: "srcQty", type: "uint256" },
                { name: "rate", type: "uint256" },
            ],
            name: "getDestQty",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [
                { name: "_kyberNetwork", type: "address" },
                { name: "_ratesContract", type: "address" },
                { name: "_admin", type: "address" },
            ],
            payable: false,
            stateMutability: "nonpayable",
            type: "constructor",
        },
        { payable: true, stateMutability: "payable", type: "fallback" },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "token", type: "address" },
                { indexed: false, name: "amount", type: "uint256" },
            ],
            name: "DepositToken",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: true, name: "origin", type: "address" },
                { indexed: false, name: "src", type: "address" },
                { indexed: false, name: "srcAmount", type: "uint256" },
                { indexed: false, name: "destToken", type: "address" },
                { indexed: false, name: "destAmount", type: "uint256" },
                { indexed: false, name: "destAddress", type: "address" },
            ],
            name: "TradeExecute",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: "enable", type: "bool" }],
            name: "TradeEnabled",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "token", type: "address" },
                { indexed: false, name: "addr", type: "address" },
                { indexed: false, name: "approve", type: "bool" },
            ],
            name: "WithdrawAddressApproved",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "token", type: "address" },
                { indexed: false, name: "amount", type: "uint256" },
                { indexed: false, name: "destination", type: "address" },
            ],
            name: "WithdrawFunds",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "network", type: "address" },
                { indexed: false, name: "rate", type: "address" },
                { indexed: false, name: "sanity", type: "address" },
            ],
            name: "SetContractAddresses",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "token", type: "address" },
                { indexed: false, name: "amount", type: "uint256" },
                { indexed: false, name: "sendTo", type: "address" },
            ],
            name: "TokenWithdraw",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "amount", type: "uint256" },
                { indexed: false, name: "sendTo", type: "address" },
            ],
            name: "EtherWithdraw",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: "pendingAdmin", type: "address" }],
            name: "TransferAdminPending",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newAdmin", type: "address" },
                { indexed: false, name: "previousAdmin", type: "address" },
            ],
            name: "AdminClaimed",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newAlerter", type: "address" },
                { indexed: false, name: "isAdd", type: "bool" },
            ],
            name: "AlerterAdded",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newOperator", type: "address" },
                { indexed: false, name: "isAdd", type: "bool" },
            ],
            name: "OperatorAdded",
            type: "event",
        },
    ],
    SANITY_RATE: [
        {
            constant: false,
            inputs: [{ name: "alerter", type: "address" }],
            name: "removeAlerter",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "pendingAdmin",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "getOperators",
            outputs: [{ name: "", type: "address[]" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "token", type: "address" },
                { name: "amount", type: "uint256" },
                { name: "sendTo", type: "address" },
            ],
            name: "withdrawToken",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newAlerter", type: "address" }],
            name: "addAlerter",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "", type: "address" }],
            name: "reasonableDiffInBps",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "srcs", type: "address[]" },
                { name: "diff", type: "uint256[]" },
            ],
            name: "setReasonableDiff",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newAdmin", type: "address" }],
            name: "transferAdmin",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [],
            name: "claimAdmin",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newAdmin", type: "address" }],
            name: "transferAdminQuickly",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "getAlerters",
            outputs: [{ name: "", type: "address[]" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "newOperator", type: "address" }],
            name: "addOperator",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [
                { name: "src", type: "address" },
                { name: "dest", type: "address" },
            ],
            name: "getSanityRate",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [{ name: "operator", type: "address" }],
            name: "removeOperator",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [{ name: "", type: "address" }],
            name: "tokenRate",
            outputs: [{ name: "", type: "uint256" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "amount", type: "uint256" },
                { name: "sendTo", type: "address" },
            ],
            name: "withdrawEther",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: false,
            inputs: [
                { name: "srcs", type: "address[]" },
                { name: "rates", type: "uint256[]" },
            ],
            name: "setSanityRates",
            outputs: [],
            payable: false,
            stateMutability: "nonpayable",
            type: "function",
        },
        {
            constant: true,
            inputs: [],
            name: "admin",
            outputs: [{ name: "", type: "address" }],
            payable: false,
            stateMutability: "view",
            type: "function",
        },
        {
            inputs: [{ name: "_admin", type: "address" }],
            payable: false,
            stateMutability: "nonpayable",
            type: "constructor",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "token", type: "address" },
                { indexed: false, name: "amount", type: "uint256" },
                { indexed: false, name: "sendTo", type: "address" },
            ],
            name: "TokenWithdraw",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "amount", type: "uint256" },
                { indexed: false, name: "sendTo", type: "address" },
            ],
            name: "EtherWithdraw",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [{ indexed: false, name: "pendingAdmin", type: "address" }],
            name: "TransferAdminPending",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newAdmin", type: "address" },
                { indexed: false, name: "previousAdmin", type: "address" },
            ],
            name: "AdminClaimed",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newAlerter", type: "address" },
                { indexed: false, name: "isAdd", type: "bool" },
            ],
            name: "AlerterAdded",
            type: "event",
        },
        {
            anonymous: false,
            inputs: [
                { indexed: false, name: "newOperator", type: "address" },
                { indexed: false, name: "isAdd", type: "bool" },
            ],
            name: "OperatorAdded",
            type: "event",
        },
    ],
};

const ETHERSCAN = {
    URL: "https://etherscan.io/",
    API_KEY: "T1QEGN2Q522UXABKW3HD5KNXVGX8SX6BYX",
    API_URL: "https://api.etherscan.io/",
};

const ACTIONS = [
    { value: "set_rates", label: "Set Rates" },
    { value: "deposit", label: "Deposite" },
    { value: "withdraw", label: "Withdraw" },
    { value: "trade", label: "Trade" },
    { value: "perpetual_trade", label: "Perpetual Trade" },
    { value: "cross_margin_transfer", label: "Cross Margin Transfer" },
];

const PATH = {
    GET_PRICE_BASE_QUOTE: "",
    GET_PRICE_ALL_BASE_QUOTE: "",
    GET_BALANCE_ALL_TOKEN_ON_BLOCKCHAIN: "",
    GET_BALANCE_ALL_TOKEN_ON_SUPPORTED_CHANGES: "",
    DEPOSIT: "",
    WITHDRAW: "",
    SET_RATE: "",
    TRADE: "",
    GET_OPEN_ORDER: "",
    CANCEL_ORDER: "",
};

const DEFAULT_TOKEN = "KNC";

const DEFAULT_TOKEN_ID = 1;

const SUPPORTED_TOKENS = {
    ETH: {
        name: "Ethereum",
        symbol: "ETH",
        icon: "eth.svg",
        decimal: 18,
        address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    },
    KNC: {
        icon: "knc.svg",
        symbol: "KNC",
        name: "KyberNetwork",
        decimal: 18,
        address: "0xdd974D5C2e2928deA5F71b9825b8b646686BD200",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "3475912029567568052224",
        maxTotalImbalance: "5709185508564730380288",
    },
    OMG: {
        icon: "omg.svg",
        symbol: "OMG",

        name: "OmiseGO",
        decimal: 18,
        address: "0xd26114cd6EE289AccF82350c8d8487fedB8A0C07",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "439794468212403470336",
        maxTotalImbalance: "722362414038872621056",
    },
    EOS: {
        symbol: "EOS",
        icon: "eos.svg",

        name: "Eos",
        decimal: 18,
        address: "0x86Fa049857E0209aa7D9e616F7eb3b3B78ECfdb0",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "938890140546807627776",
        maxTotalImbalance: "1542127055848131526656",
    },
    SALT: {
        symbol: "SALT",
        icon: "salt.svg",
        name: "Salt",
        decimal: 8,
        address: "0x4156d3342d5c385a87d264f90653733592000581",
        minimalRecordResolution: "10000",
        maxPerBlockImbalance: "123819203326",
        maxTotalImbalance: "123819203326",
    },
    SNT: {
        symbol: "SNT",
        icon: "snt.svg",
        name: "STATUS",
        decimal: 18,
        address: "0x744d70fdbe2ba4cf95131626614a1763df805b9e",
        minimalRecordResolution: "10000000000000000",
        maxPerBlockImbalance: "43262133595415336976384",
        maxTotalImbalance: "52109239915677776609280",
    },
    ELF: {
        symbol: "ELF",
        icon: "elf.svg",

        name: "AELF",
        decimal: 18,
        address: "0xbf2179859fc6d5bee9bf9158632dc51678a4100e",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "5906192156691986907136",
        maxTotalImbalance: "7114008452735498715136",
    },
    POWR: {
        symbol: "POWR",
        icon: "powr.svg",

        name: "Power Ledger",
        decimal: 6,
        address: "0x595832f8fc6bf59c85c527fec3740a1b7a361269",
        minimalRecordResolution: "1000",
        maxPerBlockImbalance: "7989613502",
        maxTotalImbalance: "7989613502",
    },
    MANA: {
        symbol: "MANA",
        icon: "mana.svg",
        name: "MANA",
        decimal: 18,
        address: "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "46289152908501773713408",
        maxTotalImbalance: "46289152908501773713408",
    },
    BAT: {
        symbol: "BAT",
        icon: "bat.svg",

        name: "Basic Attention Token",
        decimal: 18,
        address: "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "13641944431813013274624",
        maxTotalImbalance: "13641944431813013274624",
    },
    REQ: {
        symbol: "REQ",
        icon: "req.svg",

        name: "Request",
        decimal: 18,
        address: "0x8f8221afbb33998d8584a2b05749ba73c37a938a",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "27470469074054960644096",
        maxTotalImbalance: "33088179999699195920384",
    },
    GTO: {
        symbol: "GTO",
        icon: "gto.svg",

        name: "Gifto",
        decimal: 5,
        address: "0xc5bbae50781be1669306b9e001eff57a2957b09d",
        minimalRecordResolution: "10",
        maxPerBlockImbalance: "1200696404",
        maxTotalImbalance: "1200696404",
    },

    APPC: {
        symbol: "APPC",
        icon: "appc.svg",

        name: "AppCoins",
        decimal: 18,
        address: "0x1a7a8bd9106f2b8d977e08582dc7d24c723ab0db",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "10010270788085346205696",
        maxTotalImbalance: "12057371164248796823552",
    },
    RDN: {
        symbol: "RDN",
        icon: "rdn.svg",

        name: "Raiden",
        decimal: 18,
        address: "0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6",
        minimalRecordResolution: "1000000000000000",
        maxPerBlockImbalance: "2392730983766020325376",
        maxTotalImbalance: "2882044469946171260928",
    },
    ENG: {
        symbol: "ENG",
        icon: "eng.svg",

        name: "Enigma",
        decimal: 8,
        address: "0xf0ee6b27b759c9893ce4f094b49ad28fd15a23e4",
        minimalRecordResolution: "10000",
        maxPerBlockImbalance: "288970915691",
        maxTotalImbalance: "348065467950",
    },
    BQX: {
        symbol: "BQX",
        icon: "bqx.svg",
        name: "Ethos",
        decimal: 8,
        address: "0x5af2be193a6abca9c8817001f45744777db30756",
        maxPerBlockImbalance: "114446593497",
        maxTotalImbalance: "114446593497",
        minimalRecordResolution: "10000",
    },

    ADX: {
        name: "AdEx",
        decimal: 4,
        address: "0x4470BB87d77b963A013DB939BE332f927f2b992e",
        minimalRecordResolution: "10",
        maxPerBlockImbalance: "1925452883",
        maxTotalImbalance: "1925452883",
        "internal use": false,
    },

    AST: {
        name: "AirSwap",
        decimal: 4,
        address: "0x27054b13b1b798b345b591a4d22e6562d47ea75a",
        minimalRecordResolution: "10",
        maxPerBlockImbalance: "1925452883",
        maxTotalImbalance: "1925452883",
        "internal use": true,
    },

    RCN: {
        name: "Ripio Credit Network",
        decimal: 18,
        address: "0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6",
        minimalRecordResolution: "10",
        maxPerBlockImbalance: "1925452883",
        maxTotalImbalance: "1925452883",
        "internal use": false,
    },

    ZIL: {
        name: "Zilliqa",
        decimal: 12,
        address: "0x05f4a42e251f2d52b8ed15e9fedaacfcef1fad27",
        minimalRecordResolution: "10",
        maxPerBlockImbalance: "1925452883",
        maxTotalImbalance: "1925452883",
        "internal use": true,
    },

    LINK: {
        name: "Chain Link",
        decimal: 18,
        address: "0x514910771af9ca656af840dff83e8264ecf986ca",
        minimalRecordResolution: "10",
        maxPerBlockImbalance: "1925452883",
        maxTotalImbalance: "1925452883",
        "internal use": true,
    },
};

const SUPPORTED_EXCHANGE = {
    BITTREX: {
        name: "bittrex",
    },
    POLONIEX: {
        name: "poloniex",
    },
    BINANCE: {
        name: "binance",
        tokens: {
            ETH: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            OMG: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            KNC: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            SNT: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            EOS: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            ELF: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            POWR: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            MANA: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            BAT: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            REQ: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            GTO: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            ENG: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            SALT: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            APPC: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            RDN: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
            BQX: "0x44d34a119ba21a42167ff8b77a88f0fc7bb2db90",
        },
    },
    BITTFINEX: {
        name: "bittfinex",
    },
    LIQUI: {
        name: "liqui",
    },
    HUOBI: {
        name: "huobi",
    },
};

const COMMISSION_TRADE = {
    // "bittex": 0.0025,
    // "binance": 0.0004,
    // "huobi": 0.002
    0: 0.0025,
    1: 0.0004,
    2: 0.002,
};

const MAPPED_ADDRESS = (function () {
    const mappedData = {};

    Object.keys(SUPPORTED_TOKENS).map((tokenSymbol) => {
        mappedData[SUPPORTED_TOKENS[tokenSymbol].address.toLowerCase()] = tokenSymbol;
    });

    return mappedData;
})();

const INNIT_TOKEN_RATES = [];

const INNIT_TOKEN_EXCHANGE_BALANCE = {};

const INNIT_TOKEN_RESERVE_BALANCE = 0;

const HIDE_ZERO_SET_RATE = true;

const MAX_CHART_POINT = 800;
const MAX_OTHER_ACTION_LENGTH = 1000;

const SUPPORTED_HOST = {
    CORE: {
        symbol: "CORE",
        name: "Core",
        sign: true,
        timeOut: 5000,
    },
    // STATE: {
    //   symbol: "STATE",
    //   name: "State",
    //   sign: false,
    //   timeOut: 10000
    // },
    STATS: {
        symbol: "STATS",
        name: "Stats",
        sign: true,
        timeOut: 10000,
    },
};

const NODE = {
    INFURA: {
        ENDPOINT: "https://dev-ethereum.knstats.com/v1/mainnet/hpc",
    },
    ETHERSCAN: {
        ENDPOINT: "",
    },
    GIVETH: {
        ENDPOINT: "https://mew.giveth.io/",
    },
    EPOOL: {
        ENDPOINT: "",
    },
};

const BLOCK_EXPLORER_URL = {
    DEFAULT: "https://etherscan.io/",
    1: "https://etherscan.io/",
    56: "https://bscscan.com/",
    97: "https://testnet.bscscan.com/",
};

const RESERVE_LIST = [
    {
        id: "Kyber v1",
        value: "0x63825c174ab367968EC60f061753D3bbD36A0D8F",
        label: "Kyber - 0x63825c174ab367968EC60f061753D3bbD36A0D8F",
    },
    {
        id: "Kyber v2",
        value: "0xaa448eff88b1e752d50b87220b543d79eac15a0e",
        label: "Kyber v2 - 0xaa448eff88b1e752d50b87220b543d79eac15a0e",
    },
    {
        id: "Prycto",
        value: "0x2aab2b157a03915c8a73adae735d0cf51c872f31",
        label: "Prycto - 0x2aab2b157a03915c8a73adae735d0cf51c872f31",
    },
    {
        id: "Olympus",
        value: "0x6f50e41885fdc44dbdf7797df0393779a9c0a3a6",
        label: "Olympus - 0x6f50e41885fdc44dbdf7797df0393779a9c0a3a6",
    },
    {
        id: "Snap",
        value: "0x4d864b5b4f866f65f53cbaad32eb9574760865e6",
        label: "Snap - 0x4d864b5b4f866f65f53cbaad32eb9574760865e6",
    },
    {
        id: "Mossland",
        value: "0x742e8BB8e6bDE9CB2DF5449f8de7510798727fB1",
        label: "Mossland - 0x742e8BB8e6bDE9CB2DF5449f8de7510798727fB1",
    },
];

const RESERVES = {
    "0x63825c174ab367968EC60f061753D3bbD36A0D8F": "Kyber",
    "0x2aab2b157a03915c8a73adae735d0cf51c872f31": "Prycto",
    "0x9d27a2d71ac44e075f764d5612581e9afc1964fd": "Orderbook",
    "0xba92981e049a79de1b79c2396d48063e02f47239": "Bancor hybrid",
    "0x44aef3101432a64d1aa16388f4b9b352b09f42a9": "Oasis hybrid",
    "0x5d154c145db2ca90b8ab5e8fe3e716afa4ab7ff0": "Uniswap hybrid",
    "0x6f50e41885fdc44dbdf7797df0393779a9c0a3a6": "Olympus",
    "0x04A487aFd662c4F9DEAcC07A7B10cFb686B682A4": "Oasis hybrid",
    "0xcb57809435c66006d16db062c285be9e890c96fc": "Virgil Capital",
    "0xd6000fda0b38f4bff4cfab188e0bd18e8725a5e7": "DutchX hybrid",
    "0x45eb33d008801d547990caf3b63b4f8ae596ea57": "REN rerserve",
    "0x57f8160e1c59d16c01bbe181fd94db4e56b60495": "WETH",
    "0x3e9FFBA3C3eB91f501817b031031a71de2d3163B": "Abyss",
    "0xa33c7c22d0bb673c2aea2c048bb883b679fa1be9": "Melonport",
    "0x13032deb2d37556cf49301f713e9d7e1d1a8b169": "Uniswap hybrid",
    "0x5b756435bf2c8895bab3e3898dd7ed2ba073d7b9": "Bancor hybrid",
    "0xa9312cb86d1e532b7c21881ce03a1a9d52f6adb1": "TTC",
    "0x8463fDa3567D9228D6Bc2A9b6219fC85a19b89aa": "Oasis hybrid",
    "0x2295fc6BC32cD12fdBb852cFf4014cEAc6d79C10": "PT",
    "0x35183769bbbf63d2b4cac32ef593f4ad08104fba": "KCC",
    "0x21433dec9cb634a23c6a4bbcce08c83f5ac2ec18": "Prycto",
    "0xfe4474d73be9307ebb5b5519dca19e8109286acb": "Tomo",
    "0x2631a5222522156dfafaa5ca8480223d6465782d": "Dether",
    "0x494696162d3c21b4b8ee08a7fcecc9b4a1dd1566": "Tvnd",
    "0xe0e1f00a2537eccdbb993929a4265658353affc6": "Mossland",
    "0x91be8fa21dc21cff073e07bae365669e154d6ee1": "BBO",
    "0xc97094dced8b43be3d275e725f41e63eba2d4cb6": "Snap",
    "0xb50b0d0ed29603c66c65c0582cf9e49b6a9e9da5": "DCC",
    "0x56e37b6b79d4e895618b8bb287748702848ae8c0": "Midas",
    "0x742e8bb8e6bde9cb2df5449f8de7510798727fb1": "Mossland",
    "0xc935cad589bebd8673104073d5a5eccfe67fb7b1": "CoinFi",
    "0x582ea0af091ae0d98fdf08216cb2846711a65f6a": "Kyber",
    "0xe1213e46efcb8785b47ae0620a51f490f747f1da": "Prycto",
    "0x4d864b5b4f866f65f53cbaad32eb9574760865e6": "Snap",
    "0x5337d1df2d450945392d60b35f562b92fd96b6b6": "Abyss",
    "0x9e2b650f890236ab49609c5a6b00cddb4e61f408": "MKR, DAI",
    "0x8bf5c569ecfd167f96fae6d9610e17571568a6a1": "DAI",
    "0x148332cd398321989f37803188b9a69fa32b133c": "Kyber",
    "0xA467b88BBF9706622be2784aF724C4B44a9d26F4": "Automated price (KNC)",
};

const WALLET_LIST = [
    {
        id: "Trust",
        value: "0xf1aa99c69715f423086008eb9d06dc1e35cc504d",
        label: "Trust - 0xf1aa99c69715f423086008eb9d06dc1e35cc504d",
    },
    {
        id: "ImToken",
        value: "0xb9E29984Fe50602E7A619662EBED4F90D93824C7",
        label: "ImToken - 0xb9E29984Fe50602E7A619662EBED4F90D93824C7",
    },
    {
        id: "Cipher",
        value: "0xDD61803d4a56C597E0fc864F7a20eC7158c6cBA5",
        label: "Cipher - 0xDD61803d4a56C597E0fc864F7a20eC7158c6cBA5",
    },
    {
        id: "olympus",
        value: "0x09227deaeE08a5Ba9D6Eb057F922aDfAd191c36c",
        label: "Olympus - 0x09227deaeE08a5Ba9D6Eb057F922aDfAd191c36c",
    },
    {
        id: "mew",
        value: "0xDECAF9CD2367cdbb726E904cD6397eDFcAe6068D",
        label: "Mew - 0xDECAF9CD2367cdbb726E904cD6397eDFcAe6068D",
    },
    {
        id: "easwap",
        value: "0xa7615CD307F323172331865181DC8b80a2834324",
        label: "Easwap - 0xa7615CD307F323172331865181DC8b80a2834324",
    },
];

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

module.exports = {
    ABIS,
    SUPPORTED_TOKENS,
    PATH,
    SUPPORTED_EXCHANGE,
    INNIT_TOKEN_RATES,
    INNIT_TOKEN_EXCHANGE_BALANCE,
    INNIT_TOKEN_RESERVE_BALANCE,
    HIDE_ZERO_SET_RATE,
    MAX_CHART_POINT,
    ETHERSCAN,
    MAX_OTHER_ACTION_LENGTH,
    MAPPED_ADDRESS,
    SUPPORTED_HOST,
    DEFAULT_TOKEN,
    DEFAULT_TOKEN_ID,
    NODE,
    ONE_DAY_IN_MS,
    COMMISSION_TRADE,
    RESERVE_LIST,
    RESERVES,
    WALLET_LIST,
    ACTIONS,
    BLOCK_EXPLORER_URL,
};
