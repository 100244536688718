export enum IntegrationName {
    "zerox" = "zerox",
    "paraswap" = "paraswap",
    "1inch" = "1inch",
    "ksw" = "ksw",
    "hashflow" = "hashflow",
    "uniswapx" = "uniswapx",
    "bebop" = "bebop",
    "beboprfqt" = "beboprfqt",
    "bebopfastrfqt" = "bebopfastrfqt",
}

export const IntegrationNames: IntegrationName[] = Object.keys(IntegrationName) as IntegrationName[];
