import React, { useState } from "react";
import { Switch } from "../../Share";
import { shortenAddress } from "../../../services/utils/converter";
import ReactTooltip from "react-tooltip";
import Select from "react-select";

const TableDataView = (props) => {
    let tokenOpts = Object.values(props.allTokens).map((tk) => {
        return { value: tk.id, label: tk.symbol };
    });
    if (props.hasNullToken) {
        tokenOpts = [{ value: 0, label: "_" }, ...tokenOpts];
    }

    return (
        <React.Fragment>
            {(props.title || props.isShowPageSize || props.buttonElements) && (
                <div className="ReactTable-header d-flex align-items-center mb-3">
                    {props.title && <div className="title">{props.title}</div>}
                    <div className={props.searchChange ? "ml-auto text-size-s-1 w-100" : "ml-auto text-size-s-1"}>
                        {props.isShowGroup && (
                            <div className="d-inline-block ml-4">
                                <label htmlFor="material-switch">
                                    <span className="text-dark mr-2">GROUP</span>
                                    <Switch onChange={props.changePivot} checked={props.isPivot} />
                                </label>
                            </div>
                        )}
                        {props.componentLeftToPageSize}
                        {props.isShowPageSize && !props.rfqPairs && (
                            <div className="d-sm-inline-block ml-4 mobile-mb-xl">
                                <span className="mr-2 text-gray-4">SHOW</span>
                                <select
                                    value={props.pageSize}
                                    className="form-control d-inline-block w-auto"
                                    onChange={props.changePageSize}
                                >
                                    <option value="20">20 rows</option>
                                    <option value="50">50 rows</option>
                                    <option value="100">100 rows</option>
                                    <option value="200">200 rows</option>
                                </select>
                            </div>
                        )}
                        {props.isShowSelectColumn && (
                            <div className="d-inline-block ml-4 dropdown dropleft">
                                <button
                                    type="button"
                                    className="btn btn-default dropdown-toggle"
                                    data-toggle="dropdown"
                                >
                                    <i className="fa fa-columns" aria-hidden="true" />
                                    <span className="caret" />
                                </button>
                                <ul className="dropdown-menu">
                                    {props.arrayColumns.map((column, i) => (
                                        <li key={i} className="dropdown-item">
                                            <input
                                                type="checkbox"
                                                onChange={() => props.changeShowColumn(column.id)}
                                                checked={props.showColumn[column.id]}
                                            />
                                            &nbsp;{column.Header}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className={props.searchChange ? "d-block d-sm-flex w-100 justify-content-between" : ""}>
                            {props.rfqPairsFilter ? (
                                <div className="rfq-params-filter">
                                    {props.rfqPairsFilter}
                                    <div className="d-flex align-items-center mobile-mb-s">
                                        <span className="mr-2 text-gray-4">SHOW</span>
                                        <input
                                            type="number"
                                            style={{ width: "80px" }}
                                            className="form-control"
                                            placeholder="rows"
                                            aria-label="rows"
                                            onChange={props.changePageSize}
                                            value={props.pageSize}
                                        />
                                    </div>
                                </div>
                            ) : (
                                props.searchChange && (
                                    <Select
                                        className="w-25 w-sm-100 mobile-mb-s"
                                        placeholder="Search tokens..."
                                        options={tokenOpts}
                                        isMulti
                                        onChange={props.searchChange}
                                        value={props.searchTokens}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    />
                                )
                            )}

                            <div className="d-flex justify-content-between text-right">
                                {props.buttonElements &&
                                    props.buttonElements.length &&
                                    props.buttonElements.map((el, i) => (
                                        <div
                                            className={
                                                props.buttonElements.length > 1 && window.innerWidth < 600
                                                    ? "mb-3 text-right"
                                                    : "d-inline-block ml-4 ml-mb-0 "
                                            }
                                            key={i}
                                        >
                                            {el}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {props.confirmation && props.confirmation.length && (
                <div>
                    Confirmed:
                    {props.confirmation.map((c, cid) => (
                        <div key={cid}>
                            <span className="text-hide-more">{shortenAddress(c.key_id, 5, 4)}</span> {c.timestamp}
                        </div>
                    ))}
                </div>
            )}
            <div style={{ width: props.width + "px" }}>{props.tableData}</div>
            <ReactTooltip />
        </React.Fragment>
    );
};

export default TableDataView;
