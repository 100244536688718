import { useEffect, useRef, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import flatten from "lodash/flatten";
import ExchangeCell from "./ExchangeCell";

import { TableData } from "components/Share";
import { saveReserveAddress } from "actions/signActions";
import LoadingIndicator from "components/_base/LoadingIndicator";

import { IOrder } from "./model";
import Filter from "./Filter";
import TransactionHashCell from "./TransactionHashCell";
import { getTradelogs, getTokenRate } from "../../services/configuration";
import TimestampCell from "./TimestampCell";
import Select, { MultiValue } from "react-select";
import "./highlight.css";
import useLocalStorage from "hook/useLocalStorageTs";

const DEFAULT_LIMIT = 1000;
const hftAddress = "0x9a5ef5f053dd2efaff0b74e1876c66f6825774af";
const hftAddressV3 = "0x24b9d98fabf4da1f69ee10775f240ae3da6856fd";
const vtAddress = "0x1a847b0d11120b8510edcd3c81c4e4249460330a";

interface IProps {
    className?: string;
}

export interface IOption {
    value: string;
    label: string;
}

const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const usdDiff = (d: IOrder, rateETH: number, reserveAddress: string) => {
    let fee = 0;
    if (d.fee_amt_converted) {
        if (d.fee_token_converted == "ETH") fee = d.fee_amt_converted * rateETH;
        if (d.fee_token_converted == "USDC") fee = d.fee_amt_converted;
    }
    if (d.exchange == "kyberswap" && d.gas_fee > 0 && d.gas_fee_native_token == "ETH") fee = d.gas_fee * rateETH;
    if (isMaker(d.maker, reserveAddress)) return (d.maker_usd_t30 || 0) - (d.taker_usd_t30 || 0) - fee;
    return (d.taker_usd_t30 || 0) - (d.maker_usd_t30 || 0) - fee;
};

const isMaker = (marker: string, reserveAddress: string) => {
    return ![
        reserveAddress.toLowerCase(),
        hftAddress.toLowerCase(),
        hftAddressV3.toLowerCase(),
        vtAddress.toLowerCase(),
    ].includes(marker.toLowerCase());
};

const profitBps = (d: IOrder, rateETH: number, reserveAddress: string) => {
    const diff = usdDiff(d, rateETH, reserveAddress);
    let outputAmt = d.maker_usd_t30;
    if (isMaker(d.maker, reserveAddress)) outputAmt = d.taker_usd_t30;

    return outputAmt > 5e-3 ? (diff / outputAmt) * 1e4 : 0;
};

const fromTimeKey = "tradeLogs_fromTime_filter";
const toTimeKey = "tradeLogs_toTime_filter";

const TradeLog: React.FC<IProps> = ({ className }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const mountedRef = useRef(false);

    const dtp = localStorage.getItem("dateTimePickerLastUpdate");
    // 3 min to reset filter cache
    if (!dtp || Date.now() - parseInt(dtp) > 180_000) {
        localStorage.removeItem(fromTimeKey);
        localStorage.removeItem(toTimeKey);
    }

    // in seconds
    const [toTime, setToTime] = useLocalStorage(
        toTimeKey,
        parseInt(localStorage.getItem(toTimeKey) || "") || Math.round(Date.now() / 1000)
    );
    const [fromTime, setFromTime] = useLocalStorage(
        fromTimeKey,
        parseInt(localStorage.getItem(fromTimeKey) || "") || toTime - 24 * 60 * 60
    );
    const [address, setAddress] = useLocalStorage("tradeLogs_address_filter", "");

    const [data, setData] = useState<IOrder[]>([]);
    const [minUSD, setMinUSD] = useLocalStorage("tradeLogs_minUSD_filter", 0);
    const [rateETH, setETHRate] = useState(2000);
    const [integrations, setIntegrations] = useLocalStorage<MultiValue<IOption>>("tradeLogs_integration_filter", []);
    const [dexOptions, setDexOptions] = useState<IOption[]>([]);
    const [tokens, setTokens] = useLocalStorage<MultiValue<IOption>>("tradeLogs_token_filter", []);
    const [tokenOptions, setTokenOptions] = useState<IOption[]>([]);
    // @ts-ignore
    const reserveAddress = useSelector((state) => state.global.reserveAddr);
    // @ts-ignore
    const apiService = useSelector((state) => state.global.apiService);
    const [explorerURL, setExplorerURL] = useLocalStorage("explorerURL", "https://etherscan.io/tx/");

    const localFilterData = () => {
        let temp = [...data];
        if (minUSD > 0) {
            temp = temp.filter((d) => d.maker_usd_t30 > minUSD || d.taker_usd_t30 > minUSD);
        }

        if (integrations?.length) {
            temp = temp.filter((d) => integrations.map((x) => x.value).includes(d.exchange));
        }

        if (tokens?.length) {
            const tokenList = tokens.map((x) => x.value);
            temp = temp.filter((d) => tokenList.includes(d.maker_token) || tokenList.includes(d.taker_token));
        }
        return temp;
    };

    const getRate = async () => {
        const res = await getTokenRate(apiService, "ETH", "USDT");
        if (res.success) {
            setETHRate(res.data.rate);
        }
    };

    useEffect(() => {
        const intervalID = setInterval(() => {
            getRate();
        }, 30_000);

        return () => {
            clearInterval(intervalID);
        };
    }, []);

    useEffect(() => {
        const fetchAddressFromCore = async () => {
            const data = await apiService.getAddress();

            if (data?.data) {
                const addressesData = data.data.addresses;
                dispatch(saveReserveAddress(addressesData.reserve));
            }
        };

        if (!mountedRef.current && !reserveAddress && !address) {
            fetchAddressFromCore();
        }
    }, [address, apiService, dispatch, reserveAddress]);

    useEffect(() => {
        setToTime(parseInt(localStorage.getItem(toTimeKey) || "") || Math.round(Date.now() / 1000));
    }, [useLocation().key]);

    useEffect(() => {
        const addr = address || [reserveAddress, hftAddress, hftAddressV3, vtAddress].join(",");

        const fetchOrders = async () => {
            let offset = 0;
            const orders = [];
            while (offset > -1) {
                const result = await getTradelogs(
                    apiService,
                    addr,
                    fromTime * 1000,
                    toTime * 1000,
                    DEFAULT_LIMIT,
                    offset
                );
                const res = result.data ?? [];
                orders.push(...res);
                if (res.length < DEFAULT_LIMIT) {
                    return orders;
                } else {
                    offset += res.length;
                }
            }
        };

        const fetch = async () => {
            setLoading(true);
            const results = await fetchOrders();
            const tokens = new Set<string>();
            const exchanges = new Set<string>();
            results?.forEach((trade: IOrder) => {
                tokens.add(trade.maker_token);
                tokens.add(trade.taker_token);
                exchanges.add(trade.exchange);
            });

            const tokenOptions: IOption[] = [];
            tokens.forEach((s: string) => {
                tokenOptions.push({ value: s, label: s });
            });
            tokenOptions.sort((a, b: IOption) => {
                return a.label > b.value ? 1 : -1;
            });
            setTokenOptions(tokenOptions);

            const dexOptions: IOption[] = [];
            exchanges.forEach((s: string) => {
                dexOptions.push({ value: s, label: capitalize(s) });
            });
            dexOptions.sort((a, b: IOption) => {
                return a.label > b.label ? 1 : -1;
            });
            setDexOptions(dexOptions);

            setData(flatten(results));
            setLoading(false);
        };

        if (reserveAddress) {
            getRate();
            fetch();
        }
    }, [address, fromTime, reserveAddress, toTime]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    const COLUMNS = [
        {
            Header: "When",
            id: "time",
            minWidth: 280,
            accessor: "time",
            Cell: TimestampCell,
        },
        {
            Header: "Input Amount",
            id: "input_amount",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return d.maker_amount.toFixed(5);
                return d.taker_amount.toFixed(5);
            },
            minWidth: 170,
            sortable: false,
        },
        {
            Header: "Input Token",
            id: "Input_token",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return d.maker_token;
                return d.taker_token;
            },
            minWidth: 80,
        },
        {
            Header: "Output Amount",
            id: "output amount",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return d.taker_amount.toFixed(5);
                return d.maker_amount.toFixed(5);
            },
            minWidth: 170,
            sortable: false,
        },
        {
            Header: "Output Token",
            id: "output_token",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return d.taker_token;
                return d.maker_token;
            },
            minWidth: 80,
        },
        {
            Header: "Input USD",
            id: "input_usd",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return parseFloat(d.maker_usd_t30?.toFixed(2) || "0");
                return parseFloat(d.taker_usd_t30?.toFixed(2) || "0");
            },
            minWidth: 100,
        },
        {
            Header: "Output USD",
            id: "maker_usd_t30",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return parseFloat(d.taker_usd_t30?.toFixed(2) || "0");
                return parseFloat(d.maker_usd_t30?.toFixed(2) || "0");
            },
            minWidth: 100,
        },
        {
            Header: "Fee",
            id: "fee",
            accessor: "fee_amt_converted",
            Cell: ({ original }: any) => {
                const d = original;
                // adhoc for kyberswapRFQ, return 0 fee
                if (d.exchange == "kyberswapRFQ") return "";
                if (d.fee_amt_converted) {
                    if (d.fee_token_converted == "USDC")
                        return `${d.fee_amt_converted.toFixed(2)} ${d.fee_token_converted}`;
                    return `${d.fee_amt_converted.toFixed(5)} ${d.fee_token_converted}`;
                }
                if (d.gas_fee) {
                    return `${d.gas_fee.toFixed(5)} ${d.gas_fee_native_token}`;
                }
                return "";
            },
            minWidth: 120,
        },
        {
            Header: "USD Diff",
            id: "usd_diff",
            accessor: (d: IOrder) => {
                return parseFloat(usdDiff(d, rateETH, reserveAddress).toFixed(2));
            },
            minWidth: 100,
        },
        {
            Header: "Profit bps",
            id: "profit_bps",
            accessor: (d: IOrder) => {
                return parseFloat(profitBps(d, rateETH, reserveAddress).toFixed(1));
            },
            minWidth: 100,
        },
        {
            Header: "Transaction",
            id: "transaction",
            accessor: "tx_hash",
            Cell: (props: any) => {
                return <TransactionHashCell value={props.value} className={props.className} explorer={explorerURL} />;
            },
            sortable: false,
            minWidth: 200,
        },
        {
            Header: "exchange",
            id: "exchange",
            accessor: "exchange",
            Cell: ExchangeCell,
            className: "text-left",
        },
    ];

    const rowClassName = (state: any, rowInfo: any) => {
        switch (true) {
            case rowInfo.row.usd_diff < -100:
                return "large-negative-usd";
            case rowInfo.row.usd_diff < -50:
                return "medium-negative-usd";
            case rowInfo.row.usd_diff < -10:
                return "small-negative-usd";
            case rowInfo.row.usd_diff > 5000:
                return "gigantic-positive-usd";
            case rowInfo.row.usd_diff > 1000:
                return "huge-positive-usd";
            case rowInfo.row.usd_diff > 100:
                return "large-positive-usd";
            case rowInfo.row.usd_diff > 50:
                return "medium-positive-usd";
            case rowInfo.row.usd_diff > 10:
                return "small-positive-usd";
            default:
                return "bg-white";
        }
    };

    const explorerOptions = [
        { value: "https://etherscan.io/tx/", label: "Etherscan" },
        { value: "https://app.blocksec.com/explorer/tx/eth/", label: "Blocksec" },
        { value: "https://metasleuth.io/result/eth/", label: "Metasleuth" },
    ];

    const componentLeftToPageSize = (
        <div className="d-inline-block ml-4 mobile-mb-s">
            <div className="d-inline-block mr-2 text-gray-4">EXPLORER</div>

            <div className="d-inline-block" style={{ minWidth: "125px" }}>
                <Select
                    className="w-100"
                    options={explorerOptions}
                    value={explorerOptions.find((e) => e.value == explorerURL)}
                    onChange={(e) => setExplorerURL(e?.value || "")}
                />
            </div>
        </div>
    );

    return (
        <div className={className}>
            <h4 className="pageTitle px-0 py-4">Trade Log</h4>
            <Filter
                fromTime={fromTime}
                toTime={toTime}
                onFromTimeChange={setFromTime}
                onToTimeChange={setToTime}
                address={address}
                onAddressChange={setAddress}
                defaultAddress={`${reserveAddress},${hftAddress},${hftAddressV3},${vtAddress}`}
                minUSD={minUSD}
                setMinUSD={setMinUSD}
                dexOptions={dexOptions}
                integrations={integrations}
                setIntegrations={setIntegrations}
                tokenOptions={tokenOptions}
                tokens={tokens}
                setTokens={setTokens}
            />

            <TableData
                id="tradeLogTbl"
                isShowPaginate
                isShowPageSize
                initialPageSize={200}
                isShowPaginateTop
                data={loading ? [] : localFilterData()}
                columns={COLUMNS}
                rowClassName={rowClassName}
                componentLeftToPageSize={componentLeftToPageSize}
            />

            {loading ? (
                <div className="loadingText">
                    <span className="text">Loading</span> <LoadingIndicator size="m" />
                </div>
            ) : null}
        </div>
    );
};

export default styled(TradeLog)`
    width: 100%;
    padding: 1vh 2vw;

    .pageTitle {
        color: #141927;
    }

    .loadingText {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.5em;

        .text {
            font-size: 16px;
        }
    }

    .rfq {
        font-size: 8px;
        position: absolute;
        top: -3px;
        left: -10px;
    }

    .v3 {
        font-size: 8px;
        position: absolute;
        top: -5px;
        left: -12px;
    }

    .one-inch-v6 {
        font-size: 9px;
        position: absolute;
        left: -3px;
    }

    .uniswapx {
        color: #222;
        font-size: 11px;
        font-weight: 100;
        position: absolute;
        top: 7px;
        left: 4px;
    }

    .bebop {
        color: #222;
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        top: 6px;
        left: 4px;
    }

    .zeroxv3 {
        font-size: 8px;
        position: absolute;
        top: -5px;
        left: -42px;
    }
`;
