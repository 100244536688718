import React, { Component } from "react";
import { connect } from "react-redux";

import TokenPairListingView from "./tokenPairListing.view";
import {
    getAllTokens,
    getPendingListToken,
    confirmListTokens,
    rejectListTokens,
    getListExchange,
    getPendingAssetExchange,
    confirmCreateAssetExchange,
    cancelConfirmPendingSettingChange,
    rejectCreateAssetExchange,
    settingChange,
    getPendingSettingChange,
    confirmPendingSettingChange,
    rejectPendingSettingChange,
} from "../../../services/configuration";
import DropdownTreeSelect from "react-dropdown-tree-select";
import data from "./data.json";
import "./index.css";

import ReactJson from "react-json-view";
import AmCharts from "@amcharts/amcharts3-react";

import AceEditor from "react-ace";
import "brace/mode/javascript";
import "brace/theme/github";

import { TableData } from "../../Share";
import * as flat from "flat";
import TokenCell from "../TokenCell";
import tokenPairListingSlice from "./tokenPairListing.slice";
import Filter from "./Filter";

@connect(
    (store) => {
        const apiService = store.global.apiService;
        const hostData = { ...store.global.hostData };
        const tokens = store.global.tokens;
        return {
            apiService,
            host: store.global.host,
            hostData,
            tokens,
        };
    },
    { setSubmitting: tokenPairListingSlice.actions.setSubmitting }
)
class TokenPairListing extends Component {
    constructor() {
        super();

        this.intiNewExchange = {
            exchange_id: "",
            symbol: "",
            future_symbol: "",
            deposit_withdraw_symbol: "",
            deposit_address: "",
            min_deposit: "",
            target_recommended: "",
            target_ratio: "",
            trading_pairs: {
                quote: {}, // map of symbol id - bool
                base: {},
            },
            future_trading_pairs: {
                quote_id: {}, // map of symbol id - bool
                base_id: {},
            },
        };

        this.initFormToken = {
            data: {
                symbol: "",
                name: "",
                asset_type: "default",
                decimals: "",
                address: "",
                transferable: true,
                margin_enable: true,
                perpetual_enable: true,

                set_rate: "not_set",
                rebalance: true,
                is_quote: true,
                is_enabled: true,

                normal_update_per_period: 0,
                max_imbalance_ratio: 0,
                transfer_gas: 0,

                margin_target_amount: 0,
                margin_min_amount: 0,
                margin_enable_with_cfg: false,

                "sanity_info.threshold": 0,
                "sanity_info.provider": "",
                "sanity_info.path": [],

                "stable_param.price_update_threshold": 0,
                "stable_param.ask_spread": 0,
                "stable_param.bid_spread": 0,
                "stable_param.single_feed_max_spread": 0,
                "stable_param.multiple_feeds_max_diff": 0,

                "target.total": 0,
                "target.reserve": 0,
                "target.buy_threshold": 0,
                "target.rebalance_threshold": 0,
                "target.transfer_threshold": 0,
                "target.min_withdraw_threshold": 0,

                "pwi.ask.a": 0,
                "pwi.ask.b": 0,
                "pwi.ask.c": 0,
                "pwi.ask.min_min_spread": 0,
                "pwi.ask.price_multiply_factor": 0,

                "pwi.bid.a": 0,
                "pwi.bid.b": 0,
                "pwi.bid.c": 0,
                "pwi.bid.min_min_spread": 0,
                "pwi.bid.price_multiply_factor": 0,

                asset_category_id: 0,
                rfq_params_base_v2_id: 0,
            },
            exchanges: [],
            newExchange: { ...this.intiNewExchange },
        };

        this.inputType = {
            symbol: "string",
            asset_type: "string",
            name: "string",
            decimals: "number",
            address: "string",
            transferable: "bool",
            is_enabled: "bool",

            normal_update_per_period: "number",
            max_imbalance_ratio: "number",
            transfer_gas: "number",

            margin_target_amount: "number",
            margin_min_amount: "number",
            margin_enable_with_cfg: "bool",

            "sanity_info.threshold": "number",
            "sanity_info.provider": "string",
            "sanity_info.path": "array",

            set_rate: "string",
            rebalance: "bool",
            is_quote: "bool",

            "stable_param.price_update_threshold": "number",
            "stable_param.ask_spread": "number",
            "stable_param.bid_spread": "number",
            "stable_param.single_feed_max_spread": "number",
            "stable_param.multiple_feeds_max_diff": "number",

            "target.total": "number",
            "target.reserve": "number",
            "target.buy_threshold": "number",
            "target.rebalance_threshold": "number",
            "target.transfer_threshold": "number",

            "pwi.ask.a": "number",
            "pwi.ask.b": "number",
            "pwi.ask.c": "number",
            "pwi.ask.min_min_spread": "number",
            "pwi.ask.price_multiply_factor": "number",

            "pwi.bid.a": "number",
            "pwi.bid.b": "number",
            "pwi.bid.c": "number",
            "pwi.bid.min_min_spread": "number",
            "pwi.bid.price_multiply_factor": "number",
            exchanges: "array",

            asset_category_id: "number",
            rfq_params_base_v2_id: "number",
        };

        this.state = {
            listToken: {},
            listExchanges: {},
            listAssetCategories: {},
            listRFQBases: {},
            pendingList: [],
            pendingChange: [],
            pendingAssetExchange: [],
            editList: {},
            initFeedWeight: {},
            editorData: "{}",
            isEdit: false,
            currentFocusToken: null,
            newToken: [JSON.parse(JSON.stringify(this.initFormToken))],
            focusToken: 0,
            allTokenData: {},
            formulaProvider: [],
            selected: {},
            selectAll: 0,
            createAssetExchangeMode: true,
            exchangeStatus: {
                binance: true,
                huobi: false,
            },
            willBeDeleteTradingPair: {},
            willBeDeleteFutureTradingPair: {},
            willBeDeleteAssetExchange: {},
            addingTradingPair: {},
            addingFutureTradingPair: {},
            updateAssetMode: false,
            pendingChangeRefactor: {
                edit: {
                    asset: {},
                    assetExchange: {},
                    tradingPair: {},
                    futureTradingPair: {},
                },
                add: {
                    asset: {},
                    assetExchange: {},
                    tradingPair: {},
                    futureTradingPair: {},
                },
                remove: {
                    asset: {},
                    assetExchange: {},
                    tradingPair: {},
                    futureTradingPair: {},
                },
            },

            // filter for list tokens
            searchTokens: [],
            searchAssetType: { value: "", label: "All" },
            searchIsEnabled: { value: "", label: "All" },
            searchCategories: [],
        };
    }

    componentDidMount() {
        this.getSettleTokens();
        this.getExchange();
        this.getFeedConfig();
        this.getAssetCategories();
        this.getRFQBases();

        this.getPendingChange();

        this.intervalUpdateListTokens = setInterval(() => {
            this.getPendingChange();
        }, 15000);

        window.addEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isShowAllAssets != this.props.isShowAllAssets) {
            this.getSettleTokens();
            this.setState({
                selected: {},
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalUpdateListTokens);
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll(e) {
        const header = document.getElementById("list-tokens-table");
        window.scrollY >= 300 + header.offsetTop
            ? header.classList.add("fixed-on-scroll")
            : header.classList.remove("fixed-on-scroll");
    }

    initTootip() {
        $(function () {
            $('[data-toggle="change-tooltip"]').tooltip({ container: "body" });
        });
    }

    async getFeedConfig() {
        const data = await this.props.apiService.getFromCore("/v3/feed-configurations");
        if (data && data.data) {
            const initFeedWeight = {};
            const arrayData = data.data;
            arrayData.map((item) => {
                if (!initFeedWeight[item.set_rate]) initFeedWeight[item.set_rate] = {};

                initFeedWeight[item.set_rate][item.name] = "";
            });

            this.setState({ initFeedWeight });
        }
    }

    reloadAllData() {
        this.resetChangeData();
        this.getSettleTokens();
        this.getExchange();
        this.getPendingChange();
    }

    resetNewToken() {
        this.setState({
            editorData: "{}",
            isEdit: false,
            newToken: [JSON.parse(JSON.stringify(this.initFormToken))],
            focusToken: 0,
        });
    }

    fromInputToJson() {
        const newTokenData = {};

        this.state.newToken.forEach((token, i) => {
            if (token.newExchange.deposit_address) {
                this.addExchange(i);
            }
        });

        this.state.newToken.forEach((token, i) => {
            const exchanges = {};
            const tokenData = token.data;

            if (tokenData["decimals"]) {
                tokenData["decimals"] = +tokenData["decimals"];
            }

            if (tokenData["set_rate"] !== "btc_feed" && tokenData["set_rate"] !== "usd_feed") {
                delete tokenData["feed_weight"];
            }

            token.exchanges.forEach((ex, exIndex) => {
                exchanges[`exchanges.${exIndex}.exchange_id`] = ex.exchange_id ? +ex.exchange_id : 0;
                exchanges[`exchanges.${exIndex}.deposit_address`] = ex.deposit_address;
                exchanges[`exchanges.${exIndex}.symbol`] = ex.symbol;
                exchanges[`exchanges.${exIndex}.future_symbol`] = ex.future_symbol;
                exchanges[`exchanges.${exIndex}.deposit_withdraw_symbol`] = ex.deposit_withdraw_symbol;
                exchanges[`exchanges.${exIndex}.id`] = ex.id;
                exchanges[`exchanges.${exIndex}.min_deposit`] = ex.min_deposit ? +ex.min_deposit : 0;

                exchanges[`exchanges.${exIndex}.target_recommended`] = ex.target_recommended
                    ? +ex.target_recommended
                    : 0;
                exchanges[`exchanges.${exIndex}.target_ratio`] = ex.target_ratio ? +ex.target_ratio : 0;

                const arrayPair = [];
                if (tokenData["is_quote"]) {
                    Object.keys(ex["trading_pairs"].quote).map((pairAsset) => {
                        if (ex["trading_pairs"].quote[pairAsset]) arrayPair.push({ base: +pairAsset });
                    });
                }
                Object.keys(ex["trading_pairs"].base).map((pairAsset) => {
                    if (ex["trading_pairs"].base[pairAsset]) arrayPair.push({ quote: +pairAsset });
                });
                exchanges[`exchanges.${exIndex}.trading_pairs`] = arrayPair;

                const arrayFuturePair = [];
                if (tokenData["is_quote"]) {
                    Object.keys(ex["future_trading_pairs"].quote_id).map((pairAsset) => {
                        if (ex["future_trading_pairs"].quote_id[pairAsset])
                            arrayFuturePair.push({ base_id: +pairAsset });
                    });
                }
                Object.keys(ex["future_trading_pairs"].base_id).map((pairAsset) => {
                    if (ex["future_trading_pairs"].base_id[pairAsset]) arrayFuturePair.push({ quote_id: +pairAsset });
                });
                exchanges[`exchanges.${exIndex}.future_trading_pairs`] = arrayFuturePair;
            });

            const unflatedObj = flat.unflatten({ ...tokenData, ...exchanges });

            const key = newTokenData[unflatedObj.symbol] ? `[${i}]` : unflatedObj.symbol;
            newTokenData[key] = unflatedObj;
        });

        if (newTokenData.rebalance) {
            delete newTokenData[""];
        }

        this.setState({
            editorData: JSON.stringify(newTokenData, null, "\t"),
        });
    }

    fromJsonToDataSubmit() {
        // need compare flat object to detect which exchange data is changed
        const editorObj = JSON.parse(this.state.editorData);

        if (editorObj && Object.keys(editorObj).length) {
            Object.keys(editorObj).map((key, index) => {
                this.checkUpdateDataAssetExchange(index);
            });
        }
    }

    fromJsonToInput() {
        const newToken = [];
        const editorObj = JSON.parse(this.state.editorData);

        Object.keys(editorObj).map((key, index) => {
            const oldTokenData = this.state.newToken.find((t) => t.data.symbol == key);

            const tokenObj = JSON.parse(JSON.stringify(this.initFormToken));
            const jsonTokenData = editorObj[key];
            const flattenData = flat.flatten(jsonTokenData);
            const flattenOldData = oldTokenData ? oldTokenData.data : null;
            let isTokenDataEdited = false;
            Object.keys(tokenObj.data).map((key) => {
                tokenObj.data[key] =
                    this.inputType[key] == "number" ? (flattenData[key] ? +flattenData[key] : 0) : flattenData[key];

                if (key == "sanity_info.path") {
                    if (
                        oldTokenData?.data?.["sanity_info.path"] &&
                        jsonTokenData.sanity_info.path &&
                        oldTokenData.data["sanity_info.path"].join(",") != jsonTokenData.sanity_info.path?.join(",")
                    ) {
                        tokenObj.data["edited"] = true;
                        isTokenDataEdited = true;
                    }
                    tokenObj.data[key] = jsonTokenData.sanity_info.path;
                }

                if (
                    !isTokenDataEdited &&
                    flattenOldData &&
                    flattenData[key] != undefined &&
                    flattenOldData[key] != undefined &&
                    flattenData[key] !== flattenOldData[key]
                ) {
                    tokenObj.data["edited"] = true;
                    isTokenDataEdited = true;
                }
            });
            if (flattenData["asset_id"]) tokenObj.data.asset_id = flattenData["asset_id"];

            tokenObj.data["symbol"] = key;

            jsonTokenData.exchanges &&
                Object.keys(jsonTokenData.exchanges).map((ex) => {
                    const newEx = jsonTokenData.exchanges[ex];
                    const assetExchangeId = newEx.id;

                    const oldEx =
                        oldTokenData && assetExchangeId >= 0
                            ? oldTokenData.exchanges.find((ex) => ex.id == assetExchangeId)
                            : null;
                    const isCreateNew = !oldEx && newEx;
                    const isExchangeChange =
                        !newEx ||
                        !oldEx ||
                        // || newEx.asset_id !== oldEx.asset_id
                        newEx.deposit_address !== oldEx.deposit_address ||
                        newEx.exchange_id !== oldEx.exchange_id ||
                        // || newEx.id !== oldEx.id
                        newEx.min_deposit !== oldEx.min_deposit ||
                        newEx.symbol !== oldEx.symbol ||
                        newEx.future_symbol !== oldEx.future_symbol ||
                        newEx.deposit_withdraw_symbol !== oldEx.deposit_withdraw_symbol ||
                        newEx.target_ratio !== oldEx.target_ratio ||
                        newEx.target_recommended !== oldEx.target_recommended;
                    const exTradingPairObj = { base: {}, quote: {} };
                    jsonTokenData.exchanges[ex].trading_pairs.map((pair) => {
                        if (pair.base != undefined) exTradingPairObj.quote[pair.base] = true;
                        if (pair.quote != undefined) exTradingPairObj.base[pair.quote] = true;
                    });
                    const exFutureTradingPairObj = { base_id: {}, quote_id: {} };
                    jsonTokenData.exchanges[ex].future_trading_pairs.map((pair) => {
                        if (pair.base_id != undefined) exFutureTradingPairObj.quote[pair.base] = true;
                        if (pair.quote_id != undefined) exFutureTradingPairObj.base[pair.quote] = true;
                    });
                    tokenObj.exchanges.push({
                        addNew: true,
                        exchange_id: jsonTokenData.exchanges[ex].exchange_id,
                        deposit_address: jsonTokenData.exchanges[ex].deposit_address,
                        symbol: jsonTokenData.exchanges[ex].symbol,
                        future_symbol: jsonTokenData.exchanges[ex].future_symbol,
                        deposit_withdraw_symbol: jsonTokenData.exchanges[ex].deposit_withdraw_symbol,
                        min_deposit: jsonTokenData.exchanges[ex].min_deposit
                            ? +jsonTokenData.exchanges[ex].min_deposit
                            : 0,
                        // withdraw_fee: jsonTokenData.exchanges[ex].withdraw_fee ? +jsonTokenData.exchanges[ex].withdraw_fee : 0,
                        target_recommended: jsonTokenData.exchanges[ex].target_recommended
                            ? +jsonTokenData.exchanges[ex].target_recommended
                            : 0,
                        target_ratio: jsonTokenData.exchanges[ex].target_ratio
                            ? +jsonTokenData.exchanges[ex].target_ratio
                            : 0,
                        trading_pairs: exTradingPairObj,
                        future_trading_pairs: exFutureTradingPairObj,
                        ...(jsonTokenData.exchanges[ex].id && {
                            id: jsonTokenData.exchanges[ex].id,
                        }),
                        ...(tokenObj.data["asset_id"] && {
                            asset_id: tokenObj.data["asset_id"],
                        }),

                        ...(isExchangeChange && {
                            selectEdited: true,

                            ...(!isCreateNew && {
                                edited: true,
                            }),
                        }),
                    });
                });

            newToken.push(tokenObj);
        });

        this.setState(
            {
                newToken,
            },
            () => {
                if (newToken && newToken.length) {
                    newToken.map((key, index) => {
                        this.checkUpdateDataAssetExchange(index);
                    });
                }
            }
        );
    }

    async onSubmitNewToken() {
        //todo check  is new exchange typing
        this.props.setSubmitting(true);

        if (!this.state.isEdit) {
            // current using form
            await this.fromInputToJson();
        } else {
            // current is in json mode, need to compare with flatten data to check with trading pare change
            // await this.fromJsonToDataSubmit()
            await this.fromJsonToInput();
        }

        await this.submitListTokens();

        this.props.setSubmitting(false);
    }

    async getSettleTokens() {
        const r = await getAllTokens(this.props.apiService);

        if (r.success && r.data) {
            const listToken = {};
            r.data.map((tokenData) => {
                listToken[tokenData.id] = tokenData;
                if (!this.state.currentFocusToken) {
                    this.setState({ currentFocusToken: tokenData });
                }
            });

            this.setState({ listToken });
        }
    }

    async getExchange() {
        const result = await getListExchange(this.props.apiService);
        if (!result.success) return;
        if (result && result.data) {
            const listExchanges = {};
            result.data.map((exData) => {
                listExchanges[exData.id] = exData;
            });
            this.setState({
                listExchanges,
            });
        }
    }

    async getAssetCategories() {
        const res = await this.props.apiService.getFromCore("/v3/asset-categories");
        if (!res.success) return;
        if (res && res.data) {
            const listAssetCategories = {};
            res.data.map((assetCategory) => {
                listAssetCategories[assetCategory.id] = assetCategory;
            });
            this.setState({
                listAssetCategories,
            });
        }
    }

    async getRFQBases() {
        const res = await this.props.apiService.getFromCore("/v3/rfq-params-base-v2");
        if (!res.success) return;
        if (res && res.data) {
            const listRFQBases = {};
            res.data.map((base) => {
                listRFQBases[base.id] = base;
            });
            this.setState({
                listRFQBases,
            });
        }
    }

    async getPendingListToken() {
        const result = await getPendingListToken(this.props.apiService);
        if (!result.success) return;
        if (result && result.data) {
            this.setState({
                pendingList: result.data,
            });
        }
    }

    async getPendingChange() {
        const result = await getPendingSettingChange(this.props.apiService);

        if (!result.success) return;

        if (result && result.data && result.data[0]) {
            await this.resetChangeData();
            await this.buildChangedData(result.data[0].change_list);
            this.setState({
                pendingChange: result.data,
            });
        }
    }

    resetChangeData() {
        this.setState({
            pendingChangeRefactor: {
                edit: {
                    asset: {},
                    assetExchange: {},
                    tradingPair: {},
                    futureTradingPair: {},
                },
                add: {
                    asset: {},
                    assetExchange: {},
                    tradingPair: {},
                    futureTradingPair: {},
                },
                remove: {
                    asset: {},
                    assetExchange: {},
                    tradingPair: {},
                    futureTradingPair: {},
                },
            },
        });
    }

    buildChangedData(arrayChange) {
        arrayChange.map((change) => {
            const changeData = change.data;
            const oldPendingChangeRef = this.state.pendingChangeRefactor;
            switch (change.type) {
                case "create_asset":
                    oldPendingChangeRef.add.asset[changeData.asset_id] = changeData;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;

                case "update_asset":
                    oldPendingChangeRef.edit.asset[changeData.asset_id] = changeData;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;

                case "create_asset_exchange":
                    oldPendingChangeRef.add.assetExchange[changeData.id] = changeData;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;

                case "update_asset_exchange":
                    oldPendingChangeRef.edit.assetExchange[changeData.id] = changeData;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;

                case "create_trading_pair": {
                    const key = changeData.asset_id + "-" + changeData.exchange_id;
                    const changeArray = oldPendingChangeRef.add.tradingPair[key] || [];
                    changeArray.push(changeData);
                    oldPendingChangeRef.add.tradingPair[key] = changeArray;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;
                }

                case "create_future_trading_pair": {
                    const key = changeData.asset_id + "-" + changeData.exchange_id;
                    const changeArray = oldPendingChangeRef.add.futureTradingPair[key] || [];
                    changeArray.push(changeData);
                    oldPendingChangeRef.add.futureTradingPair[key] = changeArray;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;
                }

                case "delete_trading_pair":
                    oldPendingChangeRef.remove.tradingPair[changeData.id] = changeData;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;

                case "delete_future_trading_pair":
                    oldPendingChangeRef.remove.futureTradingPair[changeData.id] = changeData;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;

                case "delete_asset_exchange":
                    oldPendingChangeRef.remove.assetExchange[changeData.id] = changeData;
                    this.setState({ pendingChangeRefactor: oldPendingChangeRef });
                    break;
                default:
                // code block
            }
        });
    }

    async getPendingAssetExchange() {
        const result = await getPendingAssetExchange(this.props.apiService);

        if (!result.success) return;
        if (result && result.data) {
            this.setState({
                pendingAssetExchange: result.data,
            });
        }
    }

    confirmListTokens = (pendingId) => {
        confirmListTokens(this.props.apiService, pendingId).then((result) => {
            if (result.success) this.clearPending();
            alert(!result.success ? result.reason : "success");
            this.getSettleTokens();
        });
    };

    async confirmCreateAssetExchange(pendingId) {
        const result = await confirmCreateAssetExchange(this.props.apiService, pendingId);
        if (result.success) {
            alert("success");
        } else {
            alert(result.reason);
        }
    }

    async confirmSettingChange(pendingId) {
        const result = await confirmPendingSettingChange(this.props.apiService, pendingId);
        if (result.success) {
            alert("success");
            this.clearPending();
            this.reloadAllData();
        } else {
            alert(result.reason);
        }
    }

    async cancelConfirmSettingChange(pendingId) {
        const result = await cancelConfirmPendingSettingChange(this.props.apiService, pendingId);
        if (result.success) {
            alert("success");
            this.clearPending();
            this.reloadAllData();
        } else {
            alert(result.reason);
        }
    }

    async rejectSettingChange(pendingId) {
        const result = await rejectPendingSettingChange(this.props.apiService, pendingId);
        if (result.success) {
            alert("success");
            this.clearPending();
            this.reloadAllData();
        } else {
            alert(result.reason);
        }
    }

    async rejectCreateAssetExchange(pendingId) {
        const result = await rejectCreateAssetExchange(this.props.apiService, pendingId);
        if (result.success) {
            alert("success");
        } else {
            alert(result.reason);
        }
    }

    rejecctListTokens = (pendingId) => {
        rejectListTokens(this.props.apiService, pendingId).then((result) => {
            alert(!result.success ? result.reason : "success");
            this.getSettleTokens();
            if (result.success) this.clearPending();
        });
    };

    clearPending = () => {
        this.setState({
            pendingList: [],
            pendingChange: [],
        });
    };

    onChangeAce = (newValue) => {
        this.setState({
            editorData: newValue,
        });
    };

    toggleEdit = (value) => {
        if (value == true) {
            this.setState({
                editorData: JSON.stringify({}),
            });
        }
        this.setState({
            isEdit: value,
        });
    };

    subComponent = (row) => {
        return (
            <div style={{ padding: "20px" }}>
                <ReactJson src={row.original} name={false} />
            </div>
        );
    };

    onClickRow(state, rowInfo) {
        this.setState({
            currentFocusToken: rowInfo.original,
        });
    }

    onClickRowPending(state, rowInfo) {
        const dataChange = rowInfo.original;
        const assetId = dataChange.data.asset_id;

        switch (dataChange.type) {
            case "create_asset":
                this.setState({
                    currentFocusToken: dataChange.data,
                });
                break;
            case "delete_trading_pair":
                Object.values(this.state.listToken).map((tokenData) => {
                    if (tokenData.exchanges && tokenData.exchanges.length) {
                        Object.values(tokenData.exchanges).map((ex) => {
                            if (ex.trading_pairs && ex.trading_pairs.length) {
                                Object.values(ex.trading_pairs).map((pair) => {
                                    if (pair.id == dataChange.data.id) {
                                        this.setState({
                                            currentFocusToken: tokenData,
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
                break;
            case ("create_trading_pair", "create_future_trading_pair"):
                if (this.state.listToken[assetId]) {
                    this.setState({
                        currentFocusToken: this.state.listToken[assetId],
                    });
                }
                break;
            case "update_asset":
                if (this.state.listToken[assetId]) {
                    this.setState({
                        currentFocusToken: this.state.listToken[assetId],
                    });
                }
                break;
            case "update_asset_exchange":
                Object.values(this.state.listToken).map((tokenData) => {
                    if (tokenData.exchanges && tokenData.exchanges.length) {
                        Object.values(tokenData.exchanges).map((ex) => {
                            if (ex.id == dataChange.data.id) {
                                this.setState({
                                    currentFocusToken: tokenData,
                                });
                            }
                        });
                    }
                });
                break;
            case "delete_future_trading_pair":
                Object.values(this.state.listToken).map((tokenData) => {
                    if (tokenData.exchanges && tokenData.exchanges.length) {
                        Object.values(tokenData.exchanges).map((ex) => {
                            if (ex.future_trading_pairs && ex.future_trading_pairs.length) {
                                Object.values(ex.future_trading_pairs).map((pair) => {
                                    if (pair.id == dataChange.data.id) {
                                        this.setState({
                                            currentFocusToken: tokenData,
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
                break;
        }
    }

    removeExchange(index, exchangeIndex) {
        const newToken = this.state.newToken;
        const ex = newToken[index].exchanges;

        if (ex[exchangeIndex].id) ex[exchangeIndex].isDeleted = true;
        else ex.splice(exchangeIndex, 1);

        this.setState(
            {
                newToken: [...newToken],
            },
            () => this.checkUpdateDataAssetExchange(index)
        );
    }

    onInputChange(index, key, e, isNumber, isArray) {
        const newToken = this.state.newToken;
        switch (key) {
            case "asset_category_id":
                newToken[index].data[key] = +e;
                newToken[index].data.edited = true;
                break;
            case "rfq_params_base_v2_id":
                newToken[index].data[key] = +e;
                newToken[index].data.edited = true;
                break;
            default:
                if (isNumber) {
                    newToken[index].data[key] = e.target.value ? +e.target.value : "";
                } else if (isArray) {
                    newToken[index].data[key] = e.target.value ? e.target.value.trim().split(",") : "";
                } else {
                    newToken[index].data[key] = e.target.value;
                }
                // newToken[index].data[key] = isNumber ? (e.target.value ? +e.target.value : '') : e.target.value
                newToken[index].data.edited = true;
                if (key == "set_rate") {
                    delete newToken[index]["feed_weight"];
                }
        }

        this.setState({
            newToken,
        });
    }

    closeModal() {
        $(`#newTokenPopup`).modal("hide");
    }

    openModal() {
        $(`#newTokenPopup`).modal("show");
    }

    closeModalPlot() {
        $(`#formulaPlotPopup`).modal("hide");
    }

    openModalPlot(a, b, c, minSpread, priceMiltiplyFactor) {
        const formulaProvider = Array.from(Array(200).keys())
            .map((x) => x / 100 - 1)
            .map((x) => {
                let y = a * x * x + b * x + c;
                if (minSpread) y = y * minSpread;
                if (priceMiltiplyFactor) y = y * priceMiltiplyFactor;
                return {
                    x,
                    y,
                };
            });
        this.setState({ formulaProvider });
        $(`#formulaPlotPopup`).modal("show");
    }

    onChangeExchange(index, key, e) {
        const newToken = this.state.newToken;
        newToken[index].newExchange[key] = e.target.value == "nil" ? null : e.target.value;

        if (newToken[index].newExchange.selectEdited) newToken[index].newExchange.edited = true;
        this.setState({
            newToken,
        });
    }

    onSelectTradingPair(index, e, type, assetId) {
        const newToken = this.state.newToken;
        newToken[index].newExchange["trading_pairs"][type][assetId] = e.target.checked;

        this.setState({
            newToken,
        });
    }

    onSelectFutureTradingPair(index, e, type, assetId) {
        const newToken = this.state.newToken;
        newToken[index].newExchange["future_trading_pairs"][type][assetId] = e.target.checked;

        this.setState({
            newToken,
        });
    }

    addExchange(index) {
        const newToken = [...this.state.newToken];
        const newExchangeData = { ...newToken[index].newExchange, addNew: true };

        if (!newExchangeData.deposit_address) {
            delete newExchangeData.deposit_address;
            // newExchangeData.deposit_address = null
        }
        newToken[index].exchanges.push(JSON.parse(JSON.stringify(newExchangeData)));

        newToken[index].newExchange = JSON.parse(JSON.stringify(this.intiNewExchange));
        this.setState(
            {
                newToken,
            },
            () => this.checkUpdateDataAssetExchange(index)
        );
    }

    checkUpdateDataAssetExchange(index) {
        const updateAssetExchangeData = [];
        const createNewAssetExchangeData = [];
        const willBeDeleteTradingPair = {};
        const willBeDeleteFutureTradingPair = {};
        const addingTradingPair = {};
        const addingFutureTradingPair = {};
        const willBeDeleteAssetExchange = {};

        this.state.newToken[index].exchanges.map((ex, exIndex) => {
            const assetId = ex.asset_id;
            const assetExchangeId = ex.id;
            const exchangeId = ex.exchange_id;
            const assetTradingPairData = ex.trading_pairs;
            const assetFutureTradingPairData = ex.future_trading_pairs;

            if (ex.addNew) {
                if (ex.selectEdited && ex.asset_id) {
                    updateAssetExchangeData.push(ex);
                    //todo check delete, add new trading pair

                    Object.keys(assetTradingPairData).map((type) => {
                        const key = type == "base" ? "quote" : "base";
                        Object.keys(assetTradingPairData[type]).map((tokenPairId) => {
                            if (assetTradingPairData[type][tokenPairId]) {
                                addingTradingPair[assetId + "-" + assetExchangeId + "-" + key + "-" + tokenPairId] =
                                    exchangeId;
                            }
                            if (this.state.listToken[ex.asset_id] && this.state.listToken[ex.asset_id].exchanges) {
                                this.state.listToken[ex.asset_id].exchanges.map((lex) => {
                                    if (lex.id === assetExchangeId && lex.trading_pairs && lex.trading_pairs.length) {
                                        lex.trading_pairs.map((exPair) => {
                                            if (exPair[key] == tokenPairId) {
                                                delete addingTradingPair[
                                                    assetId + "-" + assetExchangeId + "-" + key + "-" + tokenPairId
                                                ];
                                                if (assetTradingPairData[type][exPair[key]] == false) {
                                                    willBeDeleteTradingPair[exPair.id] = {
                                                        ...exPair,
                                                        asset_id: assetId,
                                                        exchange_id: exchangeId,
                                                        id: exPair.id,
                                                    };
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    });

                    Object.keys(assetFutureTradingPairData).map((type) => {
                        const key = type == "base_id" ? "quote_id" : "base_id";
                        Object.keys(assetFutureTradingPairData[type]).map((tokenPairId) => {
                            if (assetFutureTradingPairData[type][tokenPairId]) {
                                addingFutureTradingPair[
                                    assetId + "-" + assetExchangeId + "-" + key + "-" + tokenPairId
                                ] = exchangeId;
                            }
                            if (this.state.listToken[ex.asset_id] && this.state.listToken[ex.asset_id].exchanges) {
                                this.state.listToken[ex.asset_id].exchanges.map((lex) => {
                                    if (
                                        lex.id === assetExchangeId &&
                                        lex.future_trading_pairs &&
                                        lex.future_trading_pairs.length
                                    ) {
                                        lex.future_trading_pairs.map((exPair) => {
                                            if (exPair[key] == tokenPairId) {
                                                delete addingFutureTradingPair[
                                                    assetId + "-" + assetExchangeId + "-" + key + "-" + tokenPairId
                                                ];
                                                if (assetFutureTradingPairData[type][exPair[key]] == false) {
                                                    willBeDeleteFutureTradingPair[exPair.id] = {
                                                        ...exPair,
                                                        asset_id: assetId,
                                                        exchange_id: exchangeId,
                                                        id: exPair.id,
                                                    };
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    });
                } else {
                    createNewAssetExchangeData.push(ex);
                }
            } else if (ex.isDeleted) {
                if (this.state.listToken[ex.asset_id] && this.state.listToken[ex.asset_id].exchanges) {
                    this.state.listToken[ex.asset_id].exchanges.map((lex) => {
                        if (lex.id === ex.id) {
                            willBeDeleteAssetExchange[ex.id] = ex;
                            lex.trading_pairs?.map((exPair) => {
                                const key = lex.asset_id == exPair.base ? "quote" : "base";
                                const tokenPairId = lex.asset_id == exPair.base ? exPair.quote : exPair.base;
                                delete addingTradingPair[
                                    assetId + "-" + assetExchangeId + "-" + key + "-" + tokenPairId
                                ];
                                willBeDeleteTradingPair[exPair.id] = {
                                    ...exPair,
                                    asset_id: assetId,
                                    exchange_id: exchangeId,
                                    id: exPair.id,
                                };
                            });

                            lex.future_trading_pairs?.map((exPair) => {
                                const key = lex.asset_id == exPair.base_id ? "quote_id" : "base_id";
                                const tokenPairId = lex.asset_id == exPair.base_id ? exPair.quote_id : exPair.base_id;
                                delete addingFutureTradingPair[
                                    assetId + "-" + assetExchangeId + "-" + key + "-" + tokenPairId
                                ];
                                willBeDeleteFutureTradingPair[exPair.id] = {
                                    ...exPair,
                                    asset_id: assetId,
                                    exchange_id: exchangeId,
                                    id: exPair.id,
                                };
                            });
                        }
                    });
                }
            }

            this.setState({
                willBeDeleteTradingPair,
                willBeDeleteFutureTradingPair,
                addingTradingPair,
                addingFutureTradingPair,
                willBeDeleteAssetExchange,
            });
        });
    }

    onToggleChecbox(index, key) {
        const newToken = this.state.newToken;
        newToken[index].data[key] = !newToken[index].data[key];
        newToken[index].data.edited = true;
        this.setState({
            newToken,
        });
    }

    onAddMoreToken() {
        const newToken = this.state.newToken;
        newToken.push(JSON.parse(JSON.stringify(this.initFormToken)));
        this.setState({
            newToken,
            // focusToken: this.state.newToken.length - 1
        });
    }

    async submitListTokens() {
        let dataRequest = [];
        if (Object.values(this.state.willBeDeleteTradingPair).length) {
            const deleteTradingPairData = Object.values(this.state.willBeDeleteTradingPair).map((item) => ({
                type: "delete_trading_pair",
                data: {
                    id: item.id,
                },
            }));
            dataRequest = [...dataRequest, ...deleteTradingPairData];
        }

        if (Object.values(this.state.willBeDeleteFutureTradingPair).length) {
            const deleteFutureTradingPairData = Object.values(this.state.willBeDeleteFutureTradingPair).map((item) => ({
                type: "delete_future_trading_pair",
                data: {
                    id: item.id,
                },
            }));
            dataRequest = [...dataRequest, ...deleteFutureTradingPairData];
        }

        if (Object.keys(this.state.addingTradingPair).length) {
            const addTradingPairData = Object.keys(this.state.addingTradingPair).map((key) => {
                const keyItem = key.split("-");
                const assetId = +keyItem[0];
                const type = keyItem[2];
                const tokenPairId = +keyItem[3];
                const exchangeId = +this.state.addingTradingPair[key];
                const assetPairType = type == "base" ? "quote" : "base";
                return {
                    type: "create_trading_pair",
                    data: {
                        asset_id: assetId,
                        exchange_id: exchangeId,
                        [type]: tokenPairId,
                        [assetPairType]: assetId,
                    },
                };
            });
            dataRequest = [...dataRequest, ...addTradingPairData];
        }

        if (Object.keys(this.state.addingFutureTradingPair).length) {
            const addFTradingPairData = Object.keys(this.state.addingFutureTradingPair).map((key) => {
                const keyItem = key.split("-");
                const assetId = +keyItem[0];
                const type = keyItem[2];
                const tokenPairId = +keyItem[3];
                const exchangeId = +this.state.addingFutureTradingPair[key];
                const assetPairType = type == "base_id" ? "quote_id" : "base_id";
                return {
                    type: "create_future_trading_pair",
                    data: {
                        asset_id: assetId,
                        exchange_id: exchangeId,
                        [type]: tokenPairId,
                        [assetPairType]: assetId,
                    },
                };
            });
            dataRequest = [...dataRequest, ...addFTradingPairData];
        }

        // let updateInfoData = []
        const editorData = JSON.parse(this.state.editorData);

        if (this.state.updateAssetMode) {
            this.state.newToken.map((newT) => {
                const hasEditedEx = newT.exchanges ? newT.exchanges.filter((ex) => ex.edited) : [];
                const tokenEditedData = hasEditedEx.map((ex) => {
                    return {
                        type: "update_asset_exchange",
                        data: {
                            id: ex.id,
                            // exchange_id: ex.exchange_id,
                            symbol: ex.symbol,
                            future_symbol: ex.future_symbol,
                            deposit_withdraw_symbol: ex.deposit_withdraw_symbol,
                            min_deposit: ex.min_deposit ? +ex.min_deposit : 0,
                            // withdraw_fee: ex.withdraw_fee ? +ex.withdraw_fee : 0,
                            target_recommended: ex.target_recommended ? +ex.target_recommended : 0,
                            target_ratio: ex.target_ratio ? +ex.target_ratio : 0,
                        },
                    };
                });
                dataRequest = [...dataRequest, ...tokenEditedData];

                const hasNewEx = newT.exchanges ? newT.exchanges.filter((ex) => !ex.id) : [];
                const tokenNewData = hasNewEx.map((ex) => {
                    return {
                        type: "create_asset_exchange",
                        data: {
                            asset_id: newT.data.asset_id,
                            symbol: newT.data.symbol,
                            future_symbol: newT.data.future_symbol,
                            deposit_withdraw_symbol: ex.deposit_withdraw_symbol,
                            exchange_id: ex.exchange_id ? +ex.exchange_id : 0,
                            min_deposit: ex.min_deposit ? +ex.min_deposit : 0,
                            // withdraw_fee: ex.withdraw_fee ? +ex.withdraw_fee : 0,
                            target_recommended: ex.target_recommended ? +ex.target_recommended : 0,
                            target_ratio: ex.target_ratio ? +ex.target_ratio : 0,
                        },
                    };
                });
                dataRequest = [...dataRequest, ...tokenNewData];

                if (newT.data.edited) {
                    const dataTokenJson = { ...editorData[newT.data.symbol] };
                    delete dataTokenJson.exchanges;
                    delete dataTokenJson.edited;
                    if (!dataTokenJson.address) delete dataTokenJson.address;
                    if (dataTokenJson.set_rate == "not_set") delete dataTokenJson.pwi;
                    if (dataTokenJson.set_rate != "btc_feed" && dataTokenJson.set_rate != "usd_feed")
                        delete dataTokenJson["feed_weight"];
                    const tokenInfoEditedData = [
                        {
                            type: "update_asset",
                            data: { ...dataTokenJson },
                        },
                    ];

                    dataRequest = [...dataRequest, ...tokenInfoEditedData];
                }
            });
        } else {
            const createNewAssetData = Object.values(editorData).map((d) => {
                delete d.edited;
                if (!d.address) delete d.address;
                if (d.set_rate == "not_set") delete d.pwi;
                if (d.set_rate != "btc_feed" && d.set_rate != "usd_feed") delete d["feed_weight"];
                return {
                    type: "create_asset",
                    data: { ...d },
                };
            });
            dataRequest = [...dataRequest, ...createNewAssetData];
        }

        if (Object.keys(this.state.willBeDeleteAssetExchange).length) {
            const removeExrData = Object.keys(this.state.willBeDeleteAssetExchange).map((id) => {
                return {
                    type: "delete_asset_exchange",
                    data: {
                        id: +id,
                    },
                };
            });
            dataRequest = [...dataRequest, ...removeExrData];
        }

        if (!dataRequest || !dataRequest.length) {
            alert("No data change");
            return;
        }

        const dataString = JSON.stringify({ change_list: this.correctDataByAssetType(dataRequest) });
        const result = await settingChange(this.props.apiService, dataString);
        if (result.success) {
            alert("success");
            this.closeModal();
            this.getPendingChange();
            this.reloadAllData();
            this.toggleEdit(false);
        } else {
            alert(result.reason);
        }
    }

    correctDataByAssetType(data) {
        return data.map((d) => {
            if ((d.type == "update_asset" || d.type == "create_asset") && d.data.asset_type == "unlisted") {
                delete d.data.perpetual_enable;
                delete d.data.set_rate;
                delete d.data.normal_update_per_period;
                delete d.data.max_imbalance_ratio;
                delete d.data.transfer_gas;
                delete d.data.sanity_info;
                delete d.data.margin_target_amount;
                delete d.data.margin_min_amount;
                delete d.data.margin_enable_with_cfg;
                delete d.data.target;
            }
            return d;
        });
    }

    onChangeFocusToken(index) {
        this.setState({
            focusToken: index,
        });
    }

    toggleTextEditor() {
        if (this.state.isEdit) {
            this.fromJsonToInput();
            // parse json editor to input
            this.setState({
                isEdit: false,
                focusToken: 0,
            });
        } else {
            // parse input to json editor
            this.fromInputToJson();
            this.setState({
                isEdit: true,
            });
        }
    }

    removeToken(index) {
        const currentTokenList = this.state.newToken;
        currentTokenList.splice(index, 1);
        this.setState({
            newToken: currentTokenList,
        });
    }

    editSelectedTokens = (isCreateAssetExchange = false) => {
        this.setState({
            createAssetExchangeMode: isCreateAssetExchange,
            updateAssetMode: true,
            willBeDeleteTradingPair: {},
            willBeDeleteFutureTradingPair: {},
            willBeDeleteAssetExchange: {},
            addingTradingPair: {},
            addingFutureTradingPair: {},
        });
        const arraySelected = Object.keys(this.state.selected).filter((s) => this.state.selected[s]);

        if (this.state.isEdit) {
            this.setState({
                isEdit: false,
            });
        }
        /// add to input
        const newToken = [];

        arraySelected.map((id) => {
            const tokenObj = JSON.parse(JSON.stringify(this.initFormToken));
            const jsonTokenData = this.state.listToken[id];
            const flattenData = flat.flatten(jsonTokenData);

            Object.keys(tokenObj.data).forEach((key) => {
                tokenObj.data[key] = flattenData[key];
                if (key === "sanity_info.path") {
                    tokenObj.data["sanity_info.path"] = jsonTokenData.sanity_info ? jsonTokenData.sanity_info.path : [];
                }
            });

            Object.keys(this.state.initFeedWeight).map((feedType) => {
                Object.keys(this.state.initFeedWeight[feedType]).map((feedName) => {
                    const key = "feed_weight." + feedName;
                    if (flattenData[key]) {
                        tokenObj.data[key] = flattenData[key];
                    }
                });
            });
            tokenObj.data["asset_id"] = flattenData["id"];

            jsonTokenData.exchanges &&
                jsonTokenData.exchanges.map((ex) => {
                    const tradingPairs = { base: {}, quote: {} };
                    const futureTradingPairs = { base_id: {}, quote_id: {} };

                    ex.trading_pairs?.map((pair) => {
                        if (pair.quote == ex.asset_id) tradingPairs.quote[pair.base] = true;
                        if (pair.base == ex.asset_id) tradingPairs.base[pair.quote] = true;
                    });

                    ex.future_trading_pairs?.map((pair) => {
                        if (pair.quote_id == ex.asset_id) futureTradingPairs.quote_id[pair.base_id] = true;
                        if (pair.base_id == ex.asset_id) futureTradingPairs.base_id[pair.quote_id] = true;
                    });

                    tokenObj.exchanges.push({
                        asset_id: ex.asset_id,
                        deposit_address: ex.deposit_address,
                        exchange_id: ex.exchange_id,
                        id: ex.id,
                        min_deposit: ex.min_deposit,
                        symbol: ex.symbol,
                        future_symbol: ex.future_symbol,
                        deposit_withdraw_symbol: ex.deposit_withdraw_symbol,
                        target_ratio: ex.target_ratio,
                        target_recommended: ex.target_recommended,
                        trading_pairs: tradingPairs,
                        future_trading_pairs: futureTradingPairs,
                    });
                });

            newToken.push(tokenObj);
        });

        this.setState(
            {
                newToken,
                focusToken: 0,
            },
            this.openModal
        );
    };

    openAddNewToken = () => {
        this.setState(
            {
                createAssetExchangeMode: false,
                updateAssetMode: false,
            },
            this.openModal
        );
    };

    toggleRow = (symbol) => {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[symbol] = !this.state.selected[symbol];
        this.setState({
            selected: newSelected,
            selectAll: 2,
        });
    };

    toggleSelectAll = () => {
        const newSelected = {};

        const mapFilterToken = {};
        this.state.searchTokens.forEach(({ value }) => {
            mapFilterToken[value] = true;
        }, {});
        const mapFilterCategory = {};
        this.state.searchCategories.forEach(({ value }) => {
            mapFilterCategory[value] = true;
        });

        const filteredData = Object.values(this.state.listToken).filter((d) => {
            let choose = true;
            if (this.state.searchTokens.length) choose &= mapFilterToken[d.symbol];
            if (this.state.searchAssetType.value) choose &= this.state.searchAssetType.value == d.asset_type;
            if (this.state.searchIsEnabled.value) {
                if (this.state.searchIsEnabled.value == "enabled") choose &= d.is_enabled;
                else choose &= !d.is_enabled;
            }
            if (this.state.searchCategories.length) choose &= mapFilterCategory[d.asset_category_id];
            return choose;
        });

        if (this.state.selectAll === 0) {
            filteredData.map((tokenSymbol) => {
                newSelected[tokenSymbol.id] = true;
            });
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0,
        });
    };

    editExchange(index, exchangeIndex) {
        const newToken = this.state.newToken;
        const exchangeData = {
            ...newToken[index].exchanges[exchangeIndex],
            selectEdited: true,
        };

        newToken[index].exchanges.splice(exchangeIndex, 1);
        newToken[index].newExchange = exchangeData;
        this.setState({
            newToken,
        });
    }

    renderTableListToken = () => {
        const arrayData = [];
        const addButton = (
            <button
                type="button"
                className="btn btn-success"
                data-toggle="modal"
                onClick={this.openAddNewToken.bind(this)}
            >
                Add New Token
            </button>
        );
        const editSelectedButton = (
            <button type="button" className="btn btn-success" onClick={() => this.editSelectedTokens(false)}>
                Edit Selected Tokens
            </button>
        );
        Object.keys(this.state.listToken).map((tokenSymbol) => {
            arrayData.push({
                ...this.state.listToken[tokenSymbol],
                selected: this.state.selected[tokenSymbol] || false,
            });
        });

        const buttonElements =
            this.state.pendingList && Object.keys(this.state.pendingList).length
                ? null
                : Object.values(this.state.selected).filter((s) => s).length
                ? [editSelectedButton]
                : [addButton];
        const sortedData = arrayData.sort((a, b) => (a.symbol < b.symbol ? -1 : 1));
        const mapFilterToken = {};
        this.state.searchTokens.forEach(({ value }) => {
            mapFilterToken[value] = true;
        }, {});
        const mapFilterCategory = {};
        this.state.searchCategories.forEach(({ value }) => {
            mapFilterCategory[value] = true;
        });

        const filteredData = sortedData.filter((d) => {
            let choose = true;
            if (this.state.searchTokens.length) choose &= mapFilterToken[d.symbol];
            if (this.state.searchAssetType.value) choose &= this.state.searchAssetType.value == d.asset_type;
            if (this.state.searchIsEnabled.value) {
                if (this.state.searchIsEnabled.value == "enabled") choose &= d.is_enabled;
                else choose &= !d.is_enabled;
            }
            if (this.state.searchCategories.length) choose &= mapFilterCategory[d.asset_category_id];
            return choose;
        });

        return (
            arrayData.length > 0 && (
                <div>
                    <div className="panel big-screen-h100" id="list-tokens-table">
                        <div className="panel-header pb-2">
                            <div className="panel-title text-dark p-0 border-0">List Tokens</div>
                            <div className="mt-2">
                                <TableData
                                    data={filteredData}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.id] === true}
                                                        onChange={() => this.toggleRow(original.id)}
                                                    />
                                                );
                                            },
                                            Header: (x) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        ref={(input) => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45,
                                        },

                                        {
                                            Header: "Token",
                                            id: "token",
                                            accessor: (d) => d.symbol.toUpperCase(),
                                            Cell: (row) => {
                                                const symbol = row.original.symbol;

                                                return <TokenCell symbol={symbol} />;
                                            },
                                        },

                                        {
                                            Header: "Name",
                                            id: "name",
                                            accessor: (d) => d.name,
                                        },

                                        {
                                            Header: "Address",
                                            id: "address",
                                            accessor: (d) => d.address,
                                        },

                                        {
                                            Header: "Decimals",
                                            id: "decimals",
                                            accessor: (d) => d.decimals,
                                        },

                                        {
                                            Header: "Category",
                                            id: "asset_category_id",
                                            accessor: (d) => this.getAssetCategoryName(d.asset_category_id),
                                        },
                                    ]}
                                    onClickRow={this.onClickRow.bind(this)}
                                    style={{ maxHeight: "calc(100vh - 140px)" }}
                                    buttonElements={buttonElements}
                                    rfqPairsFilter={<div className="w-75" />}
                                    rfqPairs
                                    searchChange
                                    isShowPaginate
                                    isShowPageSize
                                    initialPageSize={20}
                                    id="list_tokens_table"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        );
    };

    renderPendingSetting = () => {
        const pendingChanges = this.state.pendingChange.filter((pending) => {
            return pending.change_list.every((change) => change.type !== "update_rfq_params");
        });

        if (pendingChanges.length === 0) {
            return null;
        }

        return pendingChanges.map((pendingItem, i) => {
            const arrayChangeTask = pendingItem.change_list;
            const arrayConfirmation = pendingItem.list_approval;
            const confirmButton = (
                <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => this.confirmSettingChange(pendingItem.id)}
                >
                    Confirm
                </button>
            );
            const cancelConfirmButton = (
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => this.cancelConfirmSettingChange(pendingItem.id)}
                >
                    Cancel Confirm
                </button>
            );
            const cancelButton = (
                <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => this.rejectSettingChange(pendingItem.id)}
                >
                    Cancel
                </button>
            );

            const isConfirmed =
                pendingItem.list_approval &&
                pendingItem.list_approval.length &&
                this.props.hostData &&
                this.props.hostData.CORE.id &&
                pendingItem.list_approval.find((app) => app.key_id == this.props.hostData.CORE.id);
            const isProposer =
                pendingItem.proposer && this.props.hostData && this.props.hostData.CORE.id == pendingItem.proposer;
            const buttonElements = [
                ...(isProposer ? [] : [isConfirmed ? cancelConfirmButton : confirmButton]),
                cancelButton,
            ];

            return (
                <div key={i} className="panel">
                    <div className="panel-header pb-4">
                        <TableData
                            style={{
                                maxHeight: "600px",
                            }}
                            data={arrayChangeTask}
                            confirmation={arrayConfirmation}
                            title={"Pending tokens"}
                            isShowPaginate={false}
                            columns={[
                                {
                                    Header: "Type",
                                    id: "type",
                                    accessor: (d) => d.type,
                                },

                                {
                                    Header: "Asset Id",
                                    id: "asseIdt",
                                    accessor: (d) => d.data.asset_id,
                                },
                                {
                                    Header: "Symbol",
                                    id: "assetSymbol",
                                    accessor: (d) => d.data.symbol,
                                },
                            ]}
                            onClickRow={this.onClickRowPending.bind(this)}
                            overWriteProps={{
                                freezeWhenExpanded: true,
                                SubComponent: this.subComponent,
                            }}
                            buttonElements={buttonElements}
                        />
                    </div>
                </div>
            );
        });
    };

    renderPendingListToken = () => {
        const arrayData = [];

        return this.state.pendingList.map((pendingItem, i) => {
            const arrayAssets = pendingItem.data.assets;
            const confirmButton = (
                <button type="button" className="btn btn-info" onClick={() => this.confirmListTokens(pendingItem.id)}>
                    Confirm
                </button>
            );
            const cancelButton = (
                <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => this.rejecctListTokens(pendingItem.id)}
                >
                    Cancel
                </button>
            );
            const buttonElements = [confirmButton, cancelButton];

            return (
                <div key={i} className="panel">
                    <div className="panel-header pb-4">
                        <TableData
                            data={arrayAssets}
                            title={"Pending tokens"}
                            isShowPaginate={false}
                            columns={[
                                {
                                    Header: "Symbol",
                                    id: "symbol",
                                    accessor: (d) => d.symbol,
                                },

                                {
                                    Header: "Name",
                                    id: "name",
                                    accessor: (d) => d.name,
                                },

                                {
                                    Header: "Address",
                                    id: "address",
                                    accessor: (d) => d.address,
                                },

                                {
                                    Header: "Decimals",
                                    id: "decimals",
                                    accessor: (d) => d.decimals,
                                },
                            ]}
                            overWriteProps={{
                                freezeWhenExpanded: true,
                                SubComponent: this.subComponent,
                            }}
                            buttonElements={buttonElements}
                        />
                    </div>
                </div>
            );
        });
    };

    renderPlotFormula = () => {
        const xyConfig = {
            type: "xy",
            theme: "none",
            dataProvider: this.state.formulaProvider,
            marginRight: 80,
            dataDateFormat: "YYYY-MM-DD",
            startDuration: 0,
            graphs: [
                {
                    xField: "x",
                    yField: "y",
                    valueField: 1,
                    bullet: "diamond",
                    bulletSize: 10,
                    bulletBorderColor: "#ffffff",
                    bulletBorderAlpha: 1,
                    bulletBorderThickness: 2,
                    // "valueField": "iv",
                    hideBulletsCount: 120,
                    position: "left",
                    // "step": 0.5,
                },
                {
                    xField: "moneyness",
                    yField: "y_density",
                    valueField: 1,
                    bullet: "round",
                    bulletSize: 10,
                    bulletBorderColor: "#ffffff",
                    bulletBorderAlpha: 1,
                    bulletBorderThickness: 2,
                    // "valueField": "iv",
                    hideBulletsCount: 120,
                    position: "left",
                    // "step": 0.5,
                },
            ],

            chartCursor: {
                pan: true,
                cursorAlpha: 0,
                valueLineAlpha: 0,
            },
        };

        return (
            <AmCharts.React
                style={{
                    width: "100%",
                    height: "600px",
                }}
                options={xyConfig}
            />
        );
    };

    toggleEnableExchange(exName, checked) {
        const currentExStatus = this.state.exchangeStatus;
        currentExStatus[exName] = checked;

        this.setState({
            exchangeStatus: currentExStatus,
        });
    }

    onChangeTreeSelect(currentNode, selectedNodes) {
        console.log("path::", currentNode.path);
    }

    assignObjectPaths = (obj, stack) => {
        Object.keys(obj).forEach((k) => {
            const node = obj[k];
            if (typeof node === "object") {
                node.path = stack ? `${stack}.${k}` : k;
                this.assignObjectPaths(node, node.path);
            }
        });
    };

    renderTreeSelect = () => (
        <React.Fragment>
            {this.assignObjectPaths(data)}
            <DropdownTreeSelect data={data} onChange={this.onChangeTreeSelect.bind(this)} className="bootstrap-demo" />
        </React.Fragment>
    );

    renderEditListToken = () => {
        return (
            <AceEditor
                mode="javascript"
                theme="github"
                name="blah2"
                width="100%"
                onChange={this.onChangeAce}
                fontSize={14}
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={true}
                value={this.state.editorData}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                    width: "100%",
                }}
            />
        );
    };

    getAssetCategoryName = (id) => {
        return this.state.listAssetCategories[id]?.name || "_";
    };

    getRFQBaseName = (id) => {
        const rfq = this.state.listRFQBases[id];
        return rfq ? `[${id}] ${rfq.name}` : "_";
    };

    render() {
        return (
            <div>
                <Filter
                    optionSymbols={Object.values(this.state.listToken).map(({ symbol }) => {
                        return {
                            value: symbol,
                            label: symbol,
                        };
                    })}
                    searchTokens={this.state.searchTokens}
                    onchangeSearchTokens={(e) => this.setState({ searchTokens: e })}
                    searchAssetType={this.state.searchAssetType}
                    onchangeSearchAssetType={(e) => this.setState({ searchAssetType: e })}
                    searchAssetState={this.state.searchIsEnabled}
                    onchangeSearchAssetState={(e) => this.setState({ searchIsEnabled: e })}
                    categoryOpts={[
                        { value: 0, label: "_" },
                        ...Object.values(this.state.listAssetCategories).map((assetCat) => {
                            return { value: assetCat.id, label: assetCat.name };
                        }),
                    ]}
                    searchCategories={this.state.searchCategories}
                    onchangeSearchCategories={(e) => this.setState({ searchCategories: e })}
                    shouldFilterAssetState={Object.values(this.state.listToken).find((d) => !d.is_enabled)}
                />
                <TokenPairListingView
                    addButton={this.addButton}
                    exchangeStatus={this.state.exchangeStatus}
                    toggleEnableExchange={this.toggleEnableExchange.bind(this)}
                    renderTreeSelect={this.renderTreeSelect.bind(this)}
                    listExchanges={this.state.listExchanges}
                    listToken={this.state.listToken}
                    isEdit={this.state.isEdit}
                    renderTableListToken={this.renderTableListToken}
                    renderPendingListToken={this.renderPendingListToken.bind(this)}
                    renderPendingSetting={this.renderPendingSetting}
                    renderEditListToken={this.renderEditListToken}
                    currentFocusToken={this.state.currentFocusToken}
                    renderPlotFormula={this.renderPlotFormula}
                    openModalPlot={this.openModalPlot.bind(this)}
                    closeModalPlot={this.closeModalPlot}
                    newToken={this.state.newToken}
                    resetNewToken={this.resetNewToken.bind(this)}
                    onInputChange={this.onInputChange.bind(this)}
                    onToggleChecbox={this.onToggleChecbox.bind(this)}
                    onSubmitNewToken={this.onSubmitNewToken.bind(this)}
                    onAddMoreToken={this.onAddMoreToken.bind(this)}
                    // createAssetExchange={this.createAssetExchange.bind(this)}

                    newExchange={this.state.newExchange}
                    newTokenExchanges={this.state.newTokenExchanges}
                    addExchange={this.addExchange.bind(this)}
                    removeExchange={this.removeExchange.bind(this)}
                    onChangeExchange={this.onChangeExchange.bind(this)}
                    onSelectTradingPair={this.onSelectTradingPair.bind(this)}
                    onSelectFutureTradingPair={this.onSelectFutureTradingPair.bind(this)}
                    toggleTextEditor={this.toggleTextEditor.bind(this)}
                    focusToken={this.state.focusToken}
                    onChangeFocusToken={this.onChangeFocusToken.bind(this)}
                    removeToken={this.removeToken.bind(this)}
                    allTokenData={this.state.allTokenData}
                    selected={this.state.selected}
                    createAssetExchangeMode={this.state.createAssetExchangeMode}
                    updateAssetMode={this.state.updateAssetMode}
                    willBeDeleteTradingPair={this.state.willBeDeleteTradingPair}
                    willBeDeleteFutureTradingPair={this.state.willBeDeleteFutureTradingPair}
                    willBeDeleteAssetExchange={this.state.willBeDeleteAssetExchange}
                    addingTradingPair={this.state.addingTradingPair}
                    addingFutureTradingPair={this.state.addingFutureTradingPair}
                    editExchange={this.editExchange.bind(this)}
                    pendingChangeRefactor={this.state.pendingChangeRefactor}
                    initFeedWeight={this.state.initFeedWeight}
                    tokens={this.props.tokens}
                    getAssetCategoryName={this.getAssetCategoryName.bind(this)}
                    listAssetCategories={this.state.listAssetCategories}
                    getRFQBaseName={this.getRFQBaseName.bind(this)}
                    listRFQBases={this.state.listRFQBases}
                />
            </div>
        );
    }
}

export default TokenPairListing;
