import { Component } from "react";
import { connect } from "react-redux";
import { getIconSource } from "utils/index.js";

import TokensService from "services/tokens";

import {
    TableData,
    SetRateDetailView,
    TradeDetailView,
    DepositDetailView,
    WithdrawDetailView,
    TransferDetailView,
} from "components/Share";

@connect((store) => {
    const apiService = store.global.apiService;
    const kyberTokens = store.global.kyberTokens;
    const tokens = store.global.tokens;
    const networkId = store.global.networkId;
    const tokensService = new TokensService({
        tokens,
        kyberTokens,
    });
    return {
        apiService,
        tokensService,
        tokens,
        networkId,
    };
})
class PendingActivity extends Component {
    constructor() {
        super();
        this.state = {
            pendingActivities: [],
            isFetching: false,
        };
    }

    componentDidMount() {
        this.getPendingActivities();
        this.intervalFetchTokenData = setInterval(this.getPendingActivities, 10000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalFetchTokenData);
    }

    refreshPending() {
        this.getPendingActivities();
    }

    getPendingActivities = async () => {
        this.setState({
            isFetching: true,
        });
        let pendingActs = await this.props.tokensService.getPendingActivities(this.props.apiService);
        if (pendingActs?.length) {
            pendingActs = pendingActs.sort((a, b) => (a.org_time < b.org_time ? 1 : -1));
        }

        this.setState({
            pendingActivities: pendingActs,
            isFetching: false,
        });
    };

    displayActionName = (actionName) => {
        switch (actionName) {
            case "set_rates":
                return "Set rate";
            case "trade":
                return "Trade";
            case "perpetual_trade":
                return "Perpetual Trade";
            case "cross_margin_transfer":
                return "Cross Margin Transfer";
            case "withdraw":
                return "Withdraw";
            case "deposit":
                return "Deposit";
            default:
                return "";
        }
    };

    SubComponent = (row) => {
        var rowData = row.original;
        switch (rowData.action) {
            case "set_rates":
                return <SetRateDetailView data={rowData} tokens={this.props.tokens} networkId={this.props.networkId} />;
            case "trade":
                return <TradeDetailView data={rowData} networkId={this.props.networkId} />;
            case "perpetual_trade":
                return <TradeDetailView data={rowData} networkId={this.props.networkId} />;
            case "cross_margin_transfer":
                return <TransferDetailView data={rowData} showSpotToMargin />;
            case "withdraw":
                return <WithdrawDetailView data={rowData} networkId={this.props.networkId} />;
            case "deposit":
                return <DepositDetailView data={rowData} networkId={this.props.networkId} />;
            case "borrow":
                return <TransferDetailView data={rowData} />;
            case "borrow_transfer":
                return <TransferDetailView data={rowData} />;
            case "transfer_repay":
                return <TransferDetailView data={rowData} />;
            default:
                return <div />;
        }
    };

    getActStatus(row) {
        let rowClass = "";
        switch (row.value) {
            case "failed":
                rowClass = "btn-danger";
                break;
            case "done":
                rowClass = "btn-success";
                break;
            case "submitted":
                rowClass = "btn-primary";
                break;
            case "pending":
                rowClass = "btn-warning";
                break;
            case "mined":
                rowClass = "btn-info";
                break;
            default:
                break;
        }

        return <div>{row.value && <span className={`btn ${rowClass} btn-label`}>{row.value}</span>}</div>;
    }

    render() {
        return (
            <div className="panel panel-table">
                <div className="panel-header">
                    <h5 className="panel-title">
                        Pending Activities
                        <div className="panel-tool">
                            <i
                                className={`fa fa-refresh pointer ml-auto ${this.state.isFetching && "fa-spin"}`}
                                onClick={this.refreshPending.bind(this)}
                            />
                        </div>
                    </h5>
                    <TableData
                        data={this.state.pendingActivities}
                        columns={[
                            {
                                Header: "Action",
                                id: "Action",
                                accessor: (d) => this.displayActionName(d.action),
                            },
                            {
                                Header: "Amount",
                                id: "Amount",
                                accessor: (d) => d.params.amount,
                            },
                            {
                                Header: "Token",
                                id: "Token",
                                accessor: (d) => d.params.asset,
                                Cell: (row) => {
                                    return row && row.original && row.original.params && row.original.params.asset ? (
                                        <div>
                                            <span>
                                                <img
                                                    style={{ width: "24px" }}
                                                    src={getIconSource(this.props.tokens[row.value]?.symbol)}
                                                    className="rounded-circle"
                                                />
                                                {this.props.tokens[row.value]?.symbol}
                                            </span>
                                            {` [${row.value}]`}
                                        </div>
                                    ) : (
                                        <div />
                                    );
                                },
                            },
                            {
                                Header: "Destination",
                                accessor: "destination",
                            },
                            {
                                Header: "Exchange",
                                accessor: "exchange_status",
                                Cell: (row) => this.getActStatus(row),
                            },
                            {
                                Header: "Mining",
                                accessor: "mining_status",
                                Cell: (row) => this.getActStatus(row),
                            },
                            {
                                expander: true,
                                Header: () => <strong>More</strong>,
                                width: 65,
                                Expander: ({ isExpanded, ...rest }) => (
                                    <div>{isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}</div>
                                ),
                                style: {
                                    cursor: "pointer",
                                    fontSize: 25,
                                    padding: "0",
                                    textAlign: "center",
                                    userSelect: "none",
                                },
                            },
                        ]}
                        overWriteProps={{
                            freezeWhenExpanded: true,
                            SubComponent: this.SubComponent,
                            noDataText: "No pending action",
                            minRows: 3,
                        }}
                    />
                </div>
                <div className="panel-body" />
            </div>
        );
    }
}

export default PendingActivity;
