import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";

import { RootState } from "reducers";
import { TimeRangePicker } from "components/Share";

import { IActionConfig, IOption } from "./model";
import otherActivitiesSlice from "./otherActivities.slice";
import LoadingIndicator from "components/_base/LoadingIndicator";

const ACTIONS: IOption<keyof IActionConfig, string>[] = [
    { value: "borrow", label: "Borrow" },
    { value: "borrow_transfer", label: "Borrow Transfer" },
    { value: "cross_margin_transfer", label: "Cross Margin Transfer" },
    { value: "deposit", label: "Deposit" },
    { value: "perpetual_trade", label: "Perpetual Trade" },
    { value: "trade", label: "Trade" },
    { value: "transfer_repay", label: "Transfer Repay" },
    { value: "withdraw", label: "Withdraw" },
];

const FetchButton = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.otherActivities.isLoading);

    return (
        <button
            type="button"
            className="btn btn-info"
            disabled={isLoading}
            onClick={() => dispatch(otherActivitiesSlice.actions.fetchRatesRequest())}
        >
            Fetch
            {isLoading ? (
                <span
                    style={{
                        marginLeft: 8,
                    }}
                >
                    <LoadingIndicator color="dark" />
                </span>
            ) : null}
        </button>
    );
};

interface IProps {
    absTime?: boolean;
    setAbsTime?: Function;
}

const FetchConfig: React.FC<IProps> = ({ absTime, setAbsTime = () => {} }) => {
    const dispatch = useDispatch();
    const actionConfig = useSelector((state: RootState) => state.otherActivities.fetchConfig.actionConfig);
    const fromTime = useSelector((state: RootState) => state.otherActivities.fetchConfig.fromTime);
    const toTime = useSelector((state: RootState) => state.otherActivities.fetchConfig.toTime);

    const handleDateChange = (date: Moment, key: "from" | "to") => {
        const value = date.valueOf();

        if (key === "from") {
            dispatch(otherActivitiesSlice.actions.updateFromTimeConfig(value));
        } else if (key === "to") {
            dispatch(otherActivitiesSlice.actions.updateToTimeConfig(value));
        }
    };

    return (
        <div className="panel">
            <div className="panel-header">
                <h4 className="panel-title">Fetch Config</h4>
            </div>
            <div className="panel-body">
                <div className="panel-row d-flex">
                    <div className="w-50">
                        <p className="text-dark my-2">Action</p>

                        {ACTIONS.map((action, i) => (
                            <div className="col-3" key={i}>
                                <div className="pretty p-default">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            const checked = e.target.checked;
                                            dispatch(
                                                otherActivitiesSlice.actions.updateActionConfig({
                                                    key: action.value,
                                                    nextValue: checked,
                                                })
                                            );
                                        }}
                                        checked={actionConfig[action.value]}
                                    />
                                    <div className="state p-success">
                                        <label>{action.label}</label>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="w-50">
                        <p className="text-dark my-2">Table Settings</p>

                        <div className="col-3">
                            <div className="pretty p-default">
                                <input
                                    type="checkbox"
                                    onChange={(e) => setAbsTime(e.target.checked)}
                                    checked={absTime}
                                />
                                <div className="state p-success">
                                    <label>Absolute Time</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel-row">
                    <TimeRangePicker
                        onDateChange={handleDateChange}
                        initFrom={moment(fromTime)}
                        initTo={moment(toTime)}
                    />
                </div>

                <div
                    className="panel-row"
                    style={{
                        paddingBottom: 0,
                    }}
                >
                    <FetchButton />
                </div>
            </div>
        </div>
    );
};

export default FetchConfig;
