import TokenCell from "../TokenCell";
import ConfigTable from "./table"

const RFQBase = () => (
    <div className="panel panel-table">
        <ConfigTable
            title="RFQ BASE"
            updateType="update_rfq_params_base_v2"
            assetChild="rfq_params_base"
            pendingUrl="/v3/setting-change-rfq-params-base-v2"

            fields={{
                symbol: {
                    title: "Asset",
                    cell: (row) => {
                        return <TokenCell symbol={row.value} />;
                    },
                    const: true,
                },
                ref_eth_amount: {
                    title: "ref eth\n amount"
                },
                eth_step: {
                    title: "eth step"
                },
                max_eth_size_buy: {
                    title: (
                        <span>
                            max eth <br /> size buy
                        </span>
                    ),
                },
                max_eth_size_sell: {
                    title: (
                        <span>
                            max eth <br /> size sell
                        </span>
                    ),
                },
                a: {
                    title: "A"
                },
                b: {
                    title: "B"
                },
                c: {
                    title: "C"
                },
                // multiply_factor: {
                //     title: "multiply factor"
                // },
                min_min: {
                    title: "min min"
                },
                max_imb: {
                    title: "max imb"
                },
                imb_price_multiplier: {
                    title: (
                        <span>
                            imb price <br /> multiplier
                        </span>
                    ),
                },
                eth_amount_to_ignore: {
                    title: (
                        <span>
                            eth amount <br /> to ignore
                        </span>
                    ),
                },
                step_multiplier: {
                    title: (
                        <span>
                            step <br /> multiplier
                        </span>
                    ),
                },
                default_quote_id: {
                    title: (
                        <span>
                            default <br /> quote id
                        </span>
                    ),
                },
                enabled: {
                    title: "Enabled",
                    type: "bool"
                },
            }}
        />
    </div>
);

export default RFQBase;
