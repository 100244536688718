import styled from "styled-components";
import { useSelector } from "react-redux";

import { getIconSource } from "utils/index.js";
import { roundingNumber } from "services/utils/converter";
import { IntegrationName } from "services/const";

import { round } from "../utils";
import { calculateInventoryValue } from "./utils";
import { useEffect, useState } from "react";
import axios from "axios";

const MTM_URL = "https://mark-to-market.stg.kyberengineering.io/";

// 2 types of assets (tokenData.info.asset_type): "default" & "unlisted"
const Background = ({ tokenData, shouldBeWarning }) => {
    if (tokenData.info.asset_type == "unlisted") {
        return <div />;
    }
    return shouldBeWarning ? (
        <div className="background" data-state="warning" style={{ width: tokenData.percent + "%" }} />
    ) : (
        <>
            <div className="background" data-state="within" style={{ width: Math.min(tokenData.percent, 100) + "%" }} />

            {tokenData.percent > 100 && (
                <div
                    className="background"
                    data-state="over"
                    style={{
                        width: Math.min(tokenData.percent - 100, 100) + "%",
                    }}
                />
            )}
        </>
    );
};

const FullDisplay = ({ className, tokenId, tokenData, onClickToken, title, shouldBeWarning, indicatorColor }) => {
    const [rate, setRate] = useState(0);
    useEffect(() => {
        getRate();
        const intervalID = setInterval(() => getRate(), 20_000);
        return () => {
            clearInterval(intervalID);
        };
    }, []);

    const getRate = () => {
        if (!tokenData?.info?.symbol) return;
        let base = tokenData.info.symbol;
        const minusIdx = base.indexOf("-");
        if (minusIdx > 0) base = base.slice(0, minusIdx);
        axios.get(`${MTM_URL}rate?base=${base}&quote=USDT`).then((res) => {
            if (res.data.success) {
                setRate(res.data.data.rate);
            }
        });
    };

    const zeroXRate = useSelector((state) => {
        const { tokenDataById } = state.dashboard;
        const { ratesByIntegration } = tokenDataById[tokenId];
        if (!ratesByIntegration) {
            return {};
        }

        const zeroxRates = ratesByIntegration.find((rate) => rate.integration === IntegrationName.zerox);
        if (!zeroxRates) {
            return {};
        }

        return {
            ask: roundingNumber(zeroxRates.afpAsk, 5),
            bid: roundingNumber(zeroxRates.afpBid, 5),
        };
    });

    const inventoryValue = calculateInventoryValue(tokenData);

    return (
        <div className={className} data-token={tokenId} title={title} onClick={() => onClickToken(tokenId)}>
            <Background tokenData={tokenData} shouldBeWarning={shouldBeWarning} />

            <div className="content">
                <div className="firstRow">
                    <div className="tokenInfo">
                        <img src={getIconSource(tokenData.info.symbol)} className="rounded-circle" />

                        <span className="tokenSymbol">{tokenData.info.symbol}</span>

                        <span className="tokenId">[{tokenId}]</span>
                    </div>

                    <div className="tokenPercent" data-warning={shouldBeWarning}>
                        {inventoryValue && (
                            <>
                                <span>{inventoryValue}</span>
                                <span className="separator">|</span>
                            </>
                        )}
                        {tokenData.info.asset_type == "default" ? (
                            <span>{round(tokenData.percent)}%</span>
                        ) : (
                            <span>
                                {round(tokenData.totalQty)} <small>{tokenData.info.symbol}</small>
                            </span>
                        )}
                    </div>
                </div>

                <div className="tokenRate">
                    <div className="infoRow">
                        <div className="label">Ask (0x):</div>

                        <div className="rates">
                            <div>{zeroXRate.ask}</div>
                        </div>
                    </div>

                    <div className="infoRow">
                        <div className="label">Bid (0x):</div>

                        <div className="rates">
                            <div>{zeroXRate.bid}</div>
                        </div>
                    </div>

                    <div className="reserveSum">
                        Reserve: {round(tokenData.reserveBalanceWithWithdraw)} / {round(tokenData.reserveTarget)}
                    </div>
                    <div className="totalSum">
                        Total: {round(tokenData.totalQty)} / {round(tokenData.totalTarget)}
                    </div>
                    {rate > 0 && <div className="totalSum">Value USD: {round(tokenData.totalQty * rate)}</div>}
                </div>

                <div
                    className="rateIndicator"
                    style={{
                        backgroundColor: indicatorColor,
                    }}
                />
            </div>
        </div>
    );
};

export default styled(FullDisplay)`
    position: relative;

    width: 12rem;
    padding: 0;
    padding-bottom: 8px;
    max-width: 45vw;

    font-size: 12px;

    background-color: #f0f0f0;
    border-radius: 3px;

    cursor: pointer;
    transition: background-color 0.3s ease;

    overflow: hidden;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        background-color: transparent;

        &[data-state="warning"] {
            background-color: #fec5bb;
        }
        &[data-state="within"] {
            background-color: #caf0f8;
        }
        &[data-state="over"] {
            background-color: #90e0ef;
        }
    }

    .content {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .firstRow {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 8px 6px;

        .tokenInfo {
            display: flex;
            align-items: center;

            font-weight: bold;

            img {
                width: 1.5rem;
                height: 1.5rem;

                margin-right: 5px;
            }

            .tokenId {
                margin-left: 5px;
            }
        }

        .tokenPercent {
            font-size: 10px;
            line-height: 1;

            padding: 4px 4px;
            border-radius: 4px;

            &[data-warning="true"] {
                font-weight: bold;
            }

            .separator {
                margin-left: 4px;
                margin-right: 4px;
            }
        }
    }

    .tokenRate {
        display: flex;
        flex-direction: column;
        padding: 0 8px;
        font-size: 10px;

        .infoRow {
            display: flex;

            .label {
                flex: 0 0;
                white-space: nowrap;
                margin-right: 4px;
            }

            .rates {
                flex: 1 1 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .rateIndicator {
        position: absolute;
        z-index: 3;

        bottom: 8px;
        right: 8px;

        width: 1rem;
        height: 1rem;

        border-radius: 50%;
    }
`;
