import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "reducers";
import { saveNetworkAddress, saveNodeEndpoint, saveNodeUrl, saveNetworkId } from "actions/signActions";
import { getAllTokens, getListExchange } from "services/configuration";
import { saveTokens, saveExchange } from "actions/globalActions";

import AdminConfig from "./AdminConfig";
import GasPrice from "./GasPrice";
import TimeZoneSelector from "./TimeZoneSelector";
import TotalETH from "./totalETH.jsx";
import ETHPrice from "./priceOfETH";

import logo from "assets/img/reserve-logo.png";
import dashboardSlice from "../Dashboard/dashboard.slice";

const Header: React.FC<{ className?: string }> = ({ className }) => {
    const dispatch = useDispatch();
    const apiService = useSelector((state: RootState) => state.global.apiService);
    const nodeEndpoint = useSelector((state: RootState) => state.global.nodeEndpoint);
    const hostData = useSelector((state: RootState) => state.global.hostData);

    const syncAllTokenData = async () => {
        dispatch(dashboardSlice.actions.getTokenDataRequest());
    };

    useEffect(() => {
        const getNetworkId = async () => {
            try {
                if (apiService && apiService.provider) {
                    const networkId = await apiService.provider.httpProvider.rpc?.eth.getChainId();

                    if (networkId) {
                        dispatch(saveNetworkId(networkId));
                    }
                }
            } catch (error) {
                console.error("Cannot get network id: ", error);
            }
        };

        if (apiService) {
            getNetworkId();
            syncAllTokenData();
        }
    }, [apiService, dispatch, hostData]);

    useEffect(() => {
        setInterval(syncAllTokenData, 30_000);
    }, []);

    useEffect(() => {
        const getTokenConfigFromCore = async () => {
            const addresses = await apiService.getAddress();
            const assets = await getAllTokens(apiService);
            const exchanges = await getListExchange(apiService);

            if (assets.success) {
                dispatch(saveTokens(assets.data));
            }

            if (exchanges.success) {
                dispatch(saveExchange(exchanges.data));
            }

            if (addresses.success) {
                dispatch(saveNetworkAddress(addresses.data.addresses));

                if (!nodeEndpoint) {
                    dispatch(saveNodeUrl(addresses.data.node_url));
                    dispatch(saveNodeEndpoint(addresses.data.node_url));
                }
            }
        };

        if (apiService) {
            getTokenConfigFromCore();
        }
    }, [apiService, dispatch, nodeEndpoint]);

    return (
        <div className={className}>
            <div className="d-none d-sm-inline">
                <a href="/">
                    <img src={logo} width="111" height="31" alt="" />
                </a>
            </div>

            <div className="rightSection w-100 h-100 d-flex justify-content-around align-items-center">
                <TotalETH />
                <ETHPrice />
                <GasPrice />
                <TimeZoneSelector />
                <AdminConfig />
            </div>
        </div>
    );
};

export default styled(Header)`
    width: 100%;
    height: 72px;

    padding-left: 16px;
    padding-right: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightSection {
        max-width: 600px !important;
    }
`;
